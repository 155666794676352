import { useEffect } from "react";
import { dispatch } from "@bsgp/lib-api/store";
import { tryit } from "@bsgp/lib-core";
import { asyncSignInWithGoogle } from "actions/adir";

const GoogleLogin = function(props) {
  const { history } = props;

  useEffect(() => {
    const { location } = history;
    const queryParams = new URLSearchParams(location.search);
    const sidFromQuery = queryParams.get("s") || "";
    const [systemID, partnerID] = sidFromQuery.split("@");

    const user = {
      partnerID: partnerID,
      systemID: systemID
    };

    dispatch(
      asyncSignInWithGoogle({
        id_token: tryit(() => history.location.state.id_token, ""),
        partnerID: user.partnerID,
        systemID: user.systemID
      })
    )
      .catch(err => {
        if (err.data) {
          const invalidAccess = tryit(() => err.data.body["invalid-access"]);
          if (invalidAccess) {
            // dispatch(signOut());
            history.replace("/");
          }
        } else {
          return {
            itMightRequireSystem: true
          };
        }
      })
      .then(data => {
        // console.log("data:", data);
        if (data && data.itMightRequireSystem) {
          history.replace("/login", {
            from: location.state.from
          });
        } else {
          history.replace("/gotoFromOrHome", {
            from: location.state.from
          });
        }
      });
  }, []);

  return true;
};

export default GoogleLogin;
