var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { memo } from "react";
import { clone } from "@bsgp/lib-core";
import { RadarChart } from "@ui5/webcomponents-react-charts";
const Builder = (props) => {
  const {
    value,
    dimensions,
    measures
  } = props;
  const newDimensions = dimensions.map((dim) => {
    const newDim = clone(dim);
    delete newDim.name;
    delete newDim.properties;
    delete newDim.type;
    return __spreadValues({
      accessor: dim.name
    }, newDim);
  });
  const newMeasures = measures.map((mes) => {
    const newMes = clone(mes);
    delete newMes.name;
    delete newMes.properties;
    delete newMes.type;
    return __spreadValues({
      accessor: mes.name
    }, newMes);
  });
  return /* @__PURE__ */ React.createElement(
    RadarChart,
    {
      dataset: value || [],
      dimensions: newDimensions,
      measures: newMeasures
    }
  );
};
export default memo(Builder);
