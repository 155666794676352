var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var forms_exports = {};
__export(forms_exports, {
  button: () => button,
  chart: () => chart,
  codeeditor: () => codeeditor,
  column: () => column,
  component: () => component,
  container: () => container,
  dialog: () => dialog,
  dimension: () => dimension,
  field: () => field,
  form: () => form,
  header: () => header,
  link: () => link,
  measure: () => measure,
  nodeeditor: () => nodeeditor,
  section: () => section,
  step: () => step,
  table: () => table,
  toolbar: () => toolbar,
  wizard: () => wizard
});
module.exports = __toCommonJS(forms_exports);
const form = {
  key: "",
  title: "",
  default: false,
  sizeV2: { value: "true", type: "Boolean", seq: 0 },
  properties: {},
  containers: []
};
const container = {
  key: "",
  title: "",
  properties: {},
  fields: []
};
const field = {
  key: "",
  label: "",
  noWrap: { value: "true", type: "Boolean", seq: 0 },
  components: []
};
const component = {
  properties: {},
  valueHelpV2: {}
};
const header = {
  key: "",
  text: "",
  type: "",
  properties: {},
  buttons: [],
  links: []
};
const button = {
  key: "",
  text: "",
  type: "",
  properties: {},
  buttons: [],
  isAction: false,
  isMenu: false
};
const link = {
  key: "",
  text: "",
  url: "",
  properties: {}
};
const table = {
  key: "",
  title: "",
  isSearch: false,
  properties: {},
  toolbars: [],
  columns: [],
  items: []
};
const column = {
  key: "",
  text: "",
  components: []
};
const dialog = {
  key: "",
  title: "",
  forms: [],
  tables: [],
  codeeditors: [],
  nodeeditors: [],
  sections: [],
  properties: {}
};
const toolbar = {
  key: "",
  text: "",
  type: "",
  properties: {},
  buttons: []
};
const codeeditor = {
  key: "",
  title: "",
  components: [],
  properties: {}
};
const nodeeditor = {
  key: "",
  title: "",
  components: [],
  properties: {}
};
const section = {
  key: "",
  title: "",
  type: "HBox",
  properties: {},
  components: [],
  sections: []
};
const wizard = {
  key: "",
  type: "Wizard",
  properties: {},
  steps: []
};
const step = {
  key: "",
  title: "",
  type: "WizardStep",
  properties: {},
  sections: []
};
const chart = {
  key: "",
  type: "BarChart",
  properties: {},
  dimensions: [],
  measures: []
};
const dimension = {
  key: "",
  type: "Dimension",
  properties: {}
};
const measure = {
  key: "",
  type: "Measure",
  properties: {}
};
