var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import React from "react";
import ComponentBuilder from "../common/ComponentBuilder";
import { SplitterElement } from "@/components";
import SectionBuilder from "./Builder";
const SectionItemBuilder = (props) => {
  const _a = props, { components, sectionType } = _a, options = __objRest(_a, ["components", "sectionType"]);
  return components.map(({ data }, idx) => {
    return data.map((component, index) => {
      switch (sectionType) {
        case "Split": {
          return /* @__PURE__ */ React.createElement(SplitterElement, __spreadValues({ key: `split-${index}` }, options), /* @__PURE__ */ React.createElement(
            SectionItem,
            __spreadProps(__spreadValues({}, component), {
              refineEventHookName: "useRefineEventForSection"
            })
          ));
        }
        default: {
          return /* @__PURE__ */ React.createElement(
            SectionItem,
            __spreadProps(__spreadValues({}, component), {
              key: `${sectionType}-${index}`,
              refineEventHookName: "useRefineEventForSection"
            })
          );
        }
      }
    });
  });
};
export const SectionItem = (component) => {
  if (component.type === "Section") {
    return /* @__PURE__ */ React.createElement(SectionBuilder, __spreadValues({}, component));
  }
  return /* @__PURE__ */ React.createElement(ComponentBuilder, __spreadValues({}, component));
};
export default SectionItemBuilder;
