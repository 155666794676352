import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import render5 from "hoc/render5";
import withReducer from "hoc/withReducer";

import adir from "reducers/adir";
import { createComponent } from "components/Validation.ui5";

function Validation(props) {
  useEffect(() => {});

  return true;
}

function mapStateToProps(state, ownProps) {
  return {
    render5Version: "20190808",
    validation: state.adir.validation || {}
  };
}

const fn = {};

export default compose(
  connect(mapStateToProps),
  withRouter,
  withReducer("adir", adir),
  render5(createComponent, fn)
)(Validation);
