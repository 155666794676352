var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var common_exports = {};
__export(common_exports, {
  buildCodeEditor: () => import_fieldComponent.buildCodeEditor,
  buildForms: () => buildForms,
  buildTables: () => buildTables,
  constructFooterData: () => constructFooterData,
  constructFormData: () => constructFormData,
  constructNodeEditorData: () => constructNodeEditorData,
  constructTableData: () => constructTableData,
  constructToolbar: () => constructToolbar,
  fieldComponent: () => import_fieldComponent.fieldComponent,
  getField: () => getField,
  getNewName: () => getNewName,
  getNewValue: () => getNewValue,
  refineEventForDialog: () => import_fieldComponent.refineEventForDialog
});
module.exports = __toCommonJS(common_exports);
var import_lib_core = require("@bsgp/lib-core");
var import_lib_core2 = require("@bsgp/lib-core");
var import_lib_quantity = require("@bsgp/lib-quantity");
var import_lib_core3 = require("@bsgp/lib-core");
var import_xlsx = require("xlsx");
var import_index = require("./index");
var import_use_default_searcher = __toESM(require("./lib/use-default-searcher"));
var import_use_pagination = __toESM(require("./lib/use-pagination"));
var import_functions = require("./lib/functions");
var import_fieldComponent = require("./fieldComponent");
var import_webcomponents_react = require("@ui5/webcomponents-react");
const timeouts = {};
function getNewName(name) {
  return [name].join("_").replace(/[^-A-Za-z0-9_.:]/g, "_");
}
function isFormElement(obj) {
  return obj.hasOwnProperty("value") || obj.hasOwnProperty("label");
}
function isFormContainer(obj) {
  return obj.hasOwnProperty("elements");
}
function isForm(obj) {
  return obj.hasOwnProperty("containers");
}
function constructFormData(form, fn, options = {}) {
  const { prevForm: prevFormFromParam } = options;
  const formKey = prevFormFromParam && prevFormFromParam.name;
  if (form === void 0) {
    return [];
  }
  if (form && !(0, import_lib_core3.isObject)(form)) {
    throw new Error("The form is not Object");
  }
  let prevUnnamedContainer;
  let prevUnnamedForm;
  return Object.keys(form).reduce(
    (acc, key) => {
      if (isFormElement(form[key])) {
        if (prevUnnamedForm === void 0 && formKey === void 0) {
          prevUnnamedForm = (0, import_lib_core.makeid)(5, "a");
          if (process.env.BABEL_ENV !== "test") {
            console.warn("Unnamed Form will lose focus after re-rendering");
          }
        }
        const prevForm = acc.find(
          ({ name }) => prevUnnamedForm !== void 0 && name === prevUnnamedForm || formKey !== void 0 && name === formKey
        );
        let formObject;
        if (prevForm === void 0) {
          formObject = {
            namedForm: false,
            name: prevUnnamedForm,
            containers: []
          };
          acc.push(formObject);
        } else {
          formObject = prevForm;
        }
        if (prevUnnamedContainer === void 0) {
          prevUnnamedContainer = (0, import_lib_core.makeid)(5, "a");
          if (process.env.BABEL_ENV !== "test") {
            console.warn(
              "Unnamed Form Container will lose focus after re-rendering"
            );
          }
        }
        const prevContainer = formObject.containers.find(
          ({ name }) => prevUnnamedContainer !== void 0 && name === prevUnnamedContainer
        );
        if (prevContainer === void 0) {
          formObject.containers.push({
            namedContainer: false,
            name: prevUnnamedContainer,
            elements: [{ name: key, field: form[key] }]
          });
        } else {
          prevContainer.elements.push({ name: key, field: form[key] });
        }
      } else if (isFormContainer(form[key])) {
        if (prevUnnamedForm === void 0 && formKey === void 0) {
          prevUnnamedForm = (0, import_lib_core.makeid)(5, "a");
          if (process.env.BABEL_ENV !== "test") {
            console.warn("Unnamed Form will lose focus after re-rendering");
          }
        }
        const prevForm = acc.find(
          ({ name }) => prevUnnamedForm !== void 0 && name === prevUnnamedForm || formKey !== void 0 && name === formKey
        );
        let formObject;
        if (prevForm === void 0) {
          formObject = {
            namedForm: false,
            name: prevUnnamedForm,
            containers: []
          };
          acc.push(formObject);
        } else {
          formObject = prevForm;
        }
        let elements;
        let containerProperties;
        if (isFormContainer(form[key])) {
          elements = form[key].elements;
          containerProperties = form[key].properties;
        } else {
          elements = form[key];
        }
        prevUnnamedContainer = void 0;
        formObject.containers.push({
          namedContainer: true,
          name: key,
          title: form[key].title,
          visible: form[key].visible,
          properties: containerProperties || {},
          elements: Object.keys(elements).map((elKey) => {
            if (isFormElement(elements[elKey])) {
              return { name: elKey, field: elements[elKey] };
            }
            throw new Error(`${elKey} is not matched with form element`);
          })
        });
      } else if (isForm(form[key])) {
        prevUnnamedForm = void 0;
        const newForms = constructFormData(form[key].containers, fn, {
          prevForm: { name: key, containers: [] }
        });
        acc.push(
          (0, import_functions.refineProps)(
            {
              namedForm: true,
              name: key,
              onSelect: form[key].onSelect,
              properties: form[key].properties,
              title: form[key].title,
              tightContainers: form[key].tightContainers,
              compactSize: form[key].compactSize,
              sizeV2: form[key].sizeV2,
              columnsXL: form[key].columnsXL,
              columnsL: form[key].columnsL,
              columnsM: form[key].columnsM,
              labelSpanM: form[key].labelSpanM,
              labelSpanL: form[key].labelSpanL,
              labelSpanXL: form[key].labelSpanXL,
              containers: (0, import_lib_core2.tryit)(() => newForms[0].containers)
            },
            fn
          )
        );
      } else if (key === "containers") {
        if (prevUnnamedForm === void 0) {
          prevUnnamedForm = (0, import_lib_core.makeid)(5, "a");
          if (process.env.BABEL_ENV !== "test") {
            console.warn("Unnamed Form will lose focus after re-rendering");
          }
        }
        const prevForm = acc.find(
          ({ name }) => prevUnnamedForm !== void 0 && name === prevUnnamedForm
        );
        let formObject;
        if (prevForm === void 0) {
          formObject = {
            namedForm: false,
            name: prevUnnamedForm,
            containers: []
          };
          acc.push(formObject);
        } else {
          formObject = prevForm;
        }
        const newForms = constructFormData(form.containers, fn);
        formObject.containers = (0, import_lib_core2.tryit)(() => newForms[0].containers);
      } else {
        if ((0, import_lib_core3.isFalsy)(form[key])) {
          return acc;
        }
        throw new Error(
          `${key} is not matched with form element, form container nor form`
        );
      }
      return acc;
    },
    prevFormFromParam ? [prevFormFromParam] : []
  );
}
function constructToolbar(toolbar, parentName, options = {}) {
  const { isReact } = options;
  if (!(0, import_lib_core3.isTruthy)(toolbar)) {
    return void 0;
  }
  const allEmpty = Object.keys(toolbar).reduce((acc, key) => {
    if ((0, import_lib_core3.isTruthy)(toolbar[key])) {
      return false;
    }
    return acc;
  }, true);
  if (allEmpty === true) {
    return void 0;
  }
  const toolbarProperties = toolbar.properties;
  const toolbarContents = (0, import_lib_core2.defined)(toolbar.content, toolbar);
  const toolbarActions = toolbar.actions || {};
  const toolbarSearch = toolbar.search;
  const usePagination2 = toolbar.usePagination;
  const useSort = toolbar.useSort;
  const hasToolbar = (0, import_lib_core3.isTruthy)(toolbar);
  let hasActions = false;
  let menuButton;
  if ((0, import_lib_core3.isObject)(toolbarActions)) {
    const keys = Object.keys(toolbarActions);
    if (keys.length > 0) {
      hasActions = true;
    }
  }
  if (hasActions) {
    menuButton = {
      name: "actions_menubutton_table_toolbar",
      value: "Actions",
      component: {
        type: "MenuButton",
        properties: {
          icon: "sap-icon://action",
          enabled: (0, import_lib_core3.isTruthy)(toolbarActions)
        }
      },
      items: toolbarActions
    };
    if (isReact) {
      menuButton.refineEventHookName = "useRefineEventForTableToolbar";
    }
  }
  const arr = Object.keys(toolbarContents).map((bcKey) => {
    const content = toolbarContents[bcKey];
    return __spreadProps(__spreadValues({
      name: [parentName, bcKey].join("_")
    }, content), {
      properties: (0, import_lib_core2.defined)(
        (0, import_lib_core2.tryit)(() => content.properties),
        {}
      )
    });
  });
  const viewSettings = [];
  if ((arr.length > 0 || menuButton) && (toolbarSearch || useSort)) {
    viewSettings.push({
      name: "vs_separator",
      component: {
        type: "Separator"
      }
    });
  }
  if (useSort) {
    const vsButton = {
      value: "",
      component: {
        type: "Button",
        properties: {
          press: () => ({ oVsDialog }) => {
            oVsDialog.open();
          },
          icon: "sap-icon://sort",
          tooltip: "Sort"
        }
      }
    };
    if (isReact) {
      vsButton.component.type = "ViewSettings";
      delete vsButton.component.properties.press;
    }
    viewSettings.push(vsButton);
  }
  const search = [];
  if ((arr.length > 0 || menuButton) && (toolbarSearch || usePagination2)) {
    search.push({
      name: "search_separator",
      component: {
        type: "Separator"
      }
    });
  }
  if (toolbarSearch) {
    search.push({
      name: "search_table_toolbar",
      value: toolbarSearch.value,
      targetColumns: toolbarSearch.targetColumns,
      component: {
        type: "SearchField",
        properties: __spreadValues({
          width: "13rem",
          placeholder: import_index.refI18n.current.getText("zSearchWithInResults")
        }, toolbarSearch.properties)
      }
    });
  }
  return hasToolbar && {
    properties: toolbarProperties || {},
    useViewSettings: viewSettings.length > 0,
    content: arr.concat(menuButton).concat(viewSettings).concat(search).filter(Boolean)
  };
}
function constructColumns(columns, options = {}) {
  const { isReact } = options;
  const columnProperties = isReact ? {} : Object.keys(columns).reduce((acc, colKey) => {
    const col = columns[colKey];
    const properties = (0, import_lib_core2.defined)(col.properties, {});
    Object.keys(properties).forEach((propKey) => {
      acc[propKey] = ["{properties/", propKey, "}"].join("");
    });
    return acc;
  }, {});
  return {
    columnProperties,
    columns: Object.keys(columns).map((colKey) => {
      const col = columns[colKey];
      if ((0, import_lib_core3.isFalsy)(col)) {
        return void 0;
      }
      const properties = (0, import_lib_core2.defined)(col.properties, {});
      const newProperties = isReact ? properties : Object.keys(columnProperties).reduce((acc, propKey) => {
        if (acc[propKey] === void 0) {
          acc[propKey] = (0, import_lib_core2.tryit)(
            () => window.sap.m.Column.getMetadata().getProperty(propKey).getDefaultValue()
          );
        }
        return acc;
      }, properties);
      if (col.component && !isReact) {
        const comps = (0, import_lib_core3.isArray)(col.component) ? col.component : [col.component];
        comps.forEach((each) => {
          if (each.properties === void 0) {
            each.properties = {};
          }
          ["editable", "enabled", "state", "inverted", "visible"].forEach(
            (sProp) => {
              if ((0, import_lib_core2.tryit)(() => each.properties[sProp]) === void 0) {
                each.properties[sProp] = [
                  "{=",
                  `\${properties/cell/${colKey}/${sProp}}`,
                  "===",
                  "undefined",
                  "?",
                  `\${properties/row/${sProp}}`,
                  ":",
                  `\${properties/cell/${colKey}/${sProp}}}`
                ].join(" ");
              }
            }
          );
        });
      }
      if ((0, import_lib_core3.isString)(col)) {
        return {
          text: col,
          value: isReact ? colKey : `{${colKey}}`,
          name: colKey,
          properties: newProperties,
          component: col.component
        };
      }
      return __spreadProps(__spreadValues({}, (0, import_functions.removeUndefinedKeys)(col)), {
        name: colKey,
        value: isReact ? colKey : `{${colKey}}`,
        properties: newProperties
      });
    }).filter(Boolean)
  };
}
function writeNewExcel(ws_data, title) {
  const wb = import_xlsx.utils.book_new();
  const ws_name = "Sheet";
  const ws = import_xlsx.utils.aoa_to_sheet(ws_data);
  ws["!rows"] = [{ hidden: true }];
  import_xlsx.utils.book_append_sheet(wb, ws, ws_name);
  const currentDT = /* @__PURE__ */ new Date();
  const options = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false
  };
  const wbName = [
    title || "Download",
    currentDT.toLocaleDateString("ko-KR", options)
  ].join(" ").replace(/\s/g, "_");
  (0, import_xlsx.writeFile)(wb, [wbName, "xlsx"].join("."));
}
function convertExcelData(tableData, columns) {
  const exportColumns = columns.reduce(
    (acc, col) => {
      const components = (0, import_lib_core3.isArray)(col.component) ? col.component : [col.component];
      const hasMultiComps = components.length > 1;
      components.forEach((comp, index) => {
        const colName = hasMultiComps ? comp.name : col.name;
        acc.names.push(colName);
        const colText = hasMultiComps ? comp.text || col.text.split("\n")[index] || col.text : col.text;
        acc.texts.push(colText);
        if ((0, import_lib_core2.tryit)(() => comp.type) === "ObjectNumber") {
          const originUnit = (0, import_lib_core2.tryit)(() => comp.properties.unit);
          if (originUnit) {
            const boundUnit = (0, import_functions.isBound)(originUnit);
            let unitColumnName = "";
            if (boundUnit) {
              unitColumnName = originUnit.replace("{= $", "").replace(/[{}]/g, "");
              comp.boundUnit = unitColumnName;
            } else {
              unitColumnName = "unit";
            }
            acc.names.push(unitColumnName);
            acc.texts.push(["Unit", colText].join("_"));
          }
        }
      });
      return acc;
    },
    { names: [], texts: [] }
  );
  const itemList = tableData.map((obj) => {
    return columns.reduce((acc, col) => {
      const components = (0, import_lib_core3.isArray)(col.component) ? col.component : [col.component];
      const hasMultiComps = components.length > 1;
      components.forEach((comp, index) => {
        const colName = hasMultiComps ? comp.name : col.name;
        if ((0, import_lib_core2.tryit)(() => comp.type) === "ObjectNumber") {
          let outUnit;
          const originUnit = (0, import_lib_core2.tryit)(() => comp.properties.unit);
          if (originUnit) {
            if (comp.boundUnit) {
              outUnit = obj[comp.boundUnit];
            } else {
              outUnit = originUnit;
            }
          }
          const outNumber = outUnit ? (0, import_fieldComponent.convertNumber)(obj[colName], outUnit, {
            isAmount: comp.properties.isAmount,
            isQuantity: comp.properties.isQuantity,
            asA1: comp.properties.asA1
          }) : obj[colName];
          acc.push((0, import_lib_core2.tryit)(() => (0, import_lib_quantity.toNumber)(outNumber), outNumber));
          if (outUnit) {
            acc.push(outUnit);
          }
        } else {
          acc.push(obj[colName]);
        }
      });
      return acc;
    }, []);
  });
  return [exportColumns.names, exportColumns.texts].concat(itemList);
}
function constructTableData(table, fn, parentName, options = {}) {
  const { isReact, refineFunc = import_functions.refineProps } = options;
  if (table === void 0) {
    return [];
  }
  return Object.keys(table).map((key) => {
    const component = table[key].component;
    let itemsData, listItemProperties;
    if ((0, import_lib_core3.isObject)(component.items) && component.isTreeTable !== true) {
      itemsData = component.items.list;
      listItemProperties = component.items.properties;
    } else {
      itemsData = component.items;
      listItemProperties = {};
    }
    const toolbar = component.toolbar || {};
    if (!toolbar.content) {
      toolbar.content = {};
    }
    if (!toolbar.actions) {
      toolbar.actions = {};
    }
    toolbar.usePagination = !!component.usePagination;
    toolbar.useSort = !!component.onSort;
    if (!component.hideDownloadButton) {
      const dtbProperties = {
        icon: "sap-icon://excel-attachment"
        // table의 타입이 treeTable일 경우 data 구조가 달라 table과 같은 컨버트 로직 작성
      };
      if (isReact) {
        dtbProperties.onClick = () => ({ columns, tableData }) => {
          const ws_data = convertExcelData(tableData, columns);
          writeNewExcel(ws_data, component.title);
        };
      } else {
        dtbProperties.press = () => ({ oTable }) => {
          const isTreeTable = oTable.getMetadata().getName().includes("TreeTable");
          let data, ws_data;
          if (isTreeTable === true) {
            const matchItem = (str, keys) => {
              let res;
              const keysOfData = keys[1] === void 0 ? keys[0] : keys[1];
              const convertedArr = Object.keys(keysOfData);
              console.log(convertedArr, "convertedArr");
              for (let index = 0; index < convertedArr.length; index++) {
                if (str.includes(convertedArr[index])) {
                  res = convertedArr[index];
                  break;
                }
              }
              return res;
            };
            data = oTable.getModel().getData().items.$sub$;
            const columns = oTable.getColumns().map((col) => col.getLabel().getText());
            const rid = oTable.getColumns().map((col) => col.sId);
            const texts = rid.map((id) => matchItem(id, data));
            const values = data.map((row) => texts.map((text) => row[text]));
            ws_data = [texts, columns, ...values];
          }
          if (isTreeTable === false) {
            data = oTable.getModel().getData();
            const oFilters = oTable.getBinding("items").aFilters;
            const oKeywordFilters = oFilters.map((each) => each.aFilters).find(Boolean);
            let downloadableItems = [];
            let noSearchKeywords = false;
            if (oKeywordFilters) {
              noSearchKeywords = oKeywordFilters[0].fnTest("");
            } else {
              noSearchKeywords = true;
            }
            if (noSearchKeywords) {
              downloadableItems = data.items;
            } else {
              downloadableItems = data.items.filter((item) => {
                return oKeywordFilters.reduce((acc, oFilter) => {
                  if (acc === true) {
                    return acc;
                  }
                  return oFilter.fnTest(item[oFilter.sPath]);
                }, false);
              });
            }
            ws_data = convertExcelData(downloadableItems, data.columns);
          }
          console.log(ws_data, "ws_data");
          writeNewExcel(ws_data, component.title);
        };
      }
      const downloadTableButton = {
        value: import_index.refI18n.current.getText("zDownloadTable"),
        properties: dtbProperties
      };
      if ((0, import_lib_core3.isTruthy)(toolbar.content) && !toolbar.content.downloadTable) {
        toolbar.actions.downloadTable = downloadTableButton;
      } else {
        toolbar.content.downloadTable = {
          value: downloadTableButton.value,
          component: {
            type: "Button",
            properties: __spreadProps(__spreadValues({}, downloadTableButton.properties), {
              icon: "sap-icon://excel-attachment",
              tooltip: downloadTableButton.value
            })
          }
        };
      }
    }
    return (0, import_functions.removeUndefinedKeys)(__spreadValues(__spreadProps(__spreadValues({
      name: isReact ? key : [parentName, key].join("_")
    }, component), {
      items: {
        list: itemsData,
        properties: listItemProperties
      },
      properties: refineFunc((0, import_lib_core2.defined)(component.properties, {}), fn),
      onSelect: refineFunc({ onSelect: component.onSelect }, fn).onSelect,
      onSort: refineFunc({ onSort: component.onSort }, fn).onSort,
      onSelectTab: refineFunc({ onSelectTab: component.onSelectTab }, fn).onSelectTab,
      onDrop: refineFunc({ onDrop: component.onDrop }, fn).onDrop,
      toolbar: constructToolbar(toolbar, key, { isReact })
    }), constructColumns(component.columns, { isReact })));
  });
}
function constructFooterData(footer, options = {}) {
  const { isReact } = options;
  if (footer === void 0) {
    return [];
  }
  const components = [];
  if (footer.hasBackButton === true) {
    const backButton = {
      name: "back",
      field: {
        value: "",
        component: {
          type: "Button",
          properties: {
            icon: "sap-icon://nav-back",
            type: window.sap.m.ButtonType.Default,
            press: (fn) => fn.onBack || (() => {
              window.history.back();
            })
          }
        }
      }
    };
    if (isReact) {
      delete backButton.field.component.properties.press;
      backButton.field.component.properties.onClick = (fn) => fn.onBack || (() => {
        window.history.back();
      });
      delete backButton.field.component.properties.type;
      backButton.field.component.properties.design = import_webcomponents_react.ButtonDesign.Default;
    }
    components.push(backButton);
  }
  components.push(
    ...Object.keys(footer).map((key) => {
      switch (key) {
        case "hasBackButton":
        case "actions": {
          return void 0;
        }
        default: {
          const primaryButtonType = (0, import_lib_core2.defined)(
            (0, import_lib_core2.tryit)(() => footer[key].component.properties.type),
            isReact ? import_webcomponents_react.ButtonDesign.Emphasized : window.sap.m.ButtonType.Emphasized
          );
          return {
            name: key,
            field: __spreadProps(__spreadValues({}, footer[key]), {
              component: __spreadProps(__spreadValues({}, footer[key].component), {
                properties: __spreadProps(__spreadValues({}, (0, import_lib_core2.tryit)(() => footer[key].component.properties, {})), {
                  [isReact ? "design" : "type"]: primaryButtonType
                })
              })
            })
          };
        }
      }
    }).filter(Boolean)
  );
  let hasActions = false;
  if ((0, import_lib_core3.isObject)(footer.actions)) {
    const keys = Object.keys(footer.actions);
    if (keys.length > 0) {
      hasActions = true;
    }
  }
  if (hasActions) {
    const menuButton = {
      name: "actions_menubutton_footer",
      field: {
        value: "Actions",
        component: {
          type: "MenuButton",
          properties: {
            icon: "sap-icon://action",
            enabled: (0, import_lib_core3.isTruthy)(footer.actions)
          }
        },
        items: footer.actions
      }
    };
    components.push(menuButton);
  }
  return components;
}
function constructNodeEditorData(nodeeditor, options = {}) {
  const { isReact } = options;
  if (nodeeditor === void 0) {
    return [];
  }
  if (nodeeditor.onLoadEditor) {
    return [nodeeditor];
  }
  return Object.keys(nodeeditor).map((key) => {
    const data = nodeeditor[key];
    const _a = data, { toolbar } = _a, rest = __objRest(_a, ["toolbar"]);
    const neToolbar = constructToolbar(toolbar, "nodeeditor", { isReact });
    return __spreadProps(__spreadValues({
      name: key
    }, rest), {
      toolbar: neToolbar
    });
  }).filter(Boolean);
}
const buildForms = (form, formContainer, formElement, hBox, component, customData, onResize) => (formData, { pageKey, getFieldWithFactory, wrapForms, hasFooter }) => {
  const hasMultiForms = formData.length > 1;
  const doWrapForms = hasMultiForms && wrapForms !== false;
  const forms = formData.map((formObject) => {
    const formName = [formObject.name, pageKey].join("_");
    const sizeV2 = formObject.sizeV2 === true ? true : false;
    const sizeOptions = {
      layout: (0, import_functions.removeUndefinedKeys)({
        columnsXL: formObject.columnsXL,
        columnsL: formObject.columnsL,
        columnsM: formObject.columnsM,
        labelSpanM: formObject.labelSpanM,
        labelSpanL: formObject.labelSpanL
      })
    };
    if (sizeV2) {
      sizeOptions.width = "100%";
      sizeOptions.layout = (0, import_lib_core2.merge)(sizeOptions.layout, {
        singleContainerFullSize: false,
        adjustLabelSpan: false,
        columnsM: 3,
        labelSpanM: 4,
        columnsL: 4
      });
    } else {
      if (formObject.containers.length > 1) {
        sizeOptions.width = "100%";
      } else {
        if ((0, import_lib_core2.tryit)(() => formObject.properties.width) === void 0) {
          sizeOptions.fieldGroupIds = [
            "toggleProperty",
            "sap.ui.layout.form.Form:setWidth:Phone:100%",
            "sap.ui.layout.form.Form:setWidth:else:20rem"
          ];
        }
      }
    }
    return form.set(__spreadProps(__spreadValues(__spreadValues({
      name: formName
    }, formObject.properties), sizeOptions), {
      title: doWrapForms ? "" : formObject.title,
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("ft-transition-for-hide");
          comp.addStyleClass("ft-label-word-break");
          comp.addStyleClass("fields-relative-position");
          comp.addStyleClass("ft-form-elements-overflow-visible");
          const range = window.sap.ui.Device.media.getCurrentRange(
            window.sap.ui.Device.media.RANGESETS.SAP_STANDARD
          );
          onResize(range, comp);
          if (formObject.compactSize === true) {
            comp.addStyleClass("sapUiSizeCompact");
          }
          if (formObject.tightContainers === true) {
            comp.addStyleClass("container-fit-content");
          } else {
            comp.removeStyleClass("container-fit-content");
          }
        }
      },
      formContainers: formObject.containers.map((ctn) => {
        const containerName = [
          formName,
          [ctn.name, pageKey].join("_")
        ].join("-");
        const properties = (0, import_lib_core2.defined)(ctn.properties, {});
        properties.expandable = true;
        return formContainer.set(__spreadProps(__spreadValues({
          name: containerName,
          title: ctn.title,
          visible: (0, import_lib_core2.defined)(ctn.visible, true)
        }, properties), {
          formElements: ctn.elements.map((el) => {
            const elName = [containerName, el.name].join("-");
            const hasMultiFields = (0, import_lib_core3.isArray)(el.field.component);
            const fields = hasMultiFields ? el.field.component.map((fieldComponent2, index) => {
              const fieldName = [elName, fieldComponent2.name].filter(Boolean).join("-");
              const oField = getFieldWithFactory(fieldName, {
                value: el.field.value[index],
                conv: (0, import_lib_core3.isArray)(el.field.conv) ? el.field.conv[index] : el.field.conv,
                component: fieldComponent2
              });
              if ((0, import_lib_core2.tryit)(() => fieldComponent2.properties.width) === "100%") {
                oField.setLayoutData(
                  component.set(window.sap.m.FlexItemData, {
                    name: `${fieldName}_layout`,
                    growFactor: 1
                  })
                );
              }
              return oField;
            }) : getFieldWithFactory([elName, "field"].join("-"), __spreadProps(__spreadValues({}, el.field), {
              key: el.name
            }));
            const inHBox = (0, import_lib_core2.defined)(el.field.inHBox, hasMultiFields);
            let noWrap = false;
            if (sizeV2 === true) {
              noWrap = true;
              if (el.field.noWrap === false) {
                noWrap = false;
              }
            } else if (el.field.noWrap !== false) {
              noWrap = true;
            }
            const feLabelProps = __spreadValues({
              name: `${elName}_label`
            }, (0, import_lib_core3.isObject)(el.field.label) ? el.field.label : { text: el.field.label });
            if (el.field.required === true) {
              feLabelProps.required = true;
            }
            return formElement.set({
              name: elName,
              visible: (0, import_lib_core2.defined)(el.field.visible, true),
              label: el.field.label === void 0 ? el.field.label : component.set(window.sap.m.Label, feLabelProps),
              fields: inHBox ? hBox.set({
                name: elName,
                wrap: noWrap ? window.sap.m.FlexWrap.NoWrap : window.sap.m.FlexWrap.Wrap,
                items: fields.map((field) => {
                  if (field.getMetadata().getName() === "sap.m.Input" && field.getWidth()) {
                    if (field.getShowValueHelp() === true) {
                      if (field.getWidth() < "5rem") {
                        console.warn(
                          [
                            "Input width must be larger",
                            "than 5rem when",
                            "showValueHelp is true"
                          ].join(" ")
                        );
                      }
                    } else {
                      if (field.getWidth() < "3rem") {
                        console.warn(
                          "Input width must be larger than 3rem"
                        );
                      }
                    }
                  }
                  return field;
                }),
                callback: (comp) => {
                  comp.addStyleClass("tiny-margin-items");
                }
              }) : fields
            });
          }).filter(Boolean)
        }));
      }).filter(Boolean)
    }));
  }).filter(Boolean);
  let formsWrapper;
  if (doWrapForms) {
    formsWrapper = component.set(window.sap.m.IconTabBar, {
      name: "formsWrapper",
      select: (oEvent) => {
        const oItem = oEvent.getParameters().item;
        const customDataList = oItem.getCustomData();
        const oCustomData = customDataList.find(
          (each) => each.getKey() === "select_handler"
        );
        if (oCustomData) {
          const { selectHandler, formKey } = oCustomData.getValue();
          if (selectHandler) {
            selectHandler({ formKey });
          }
        }
      },
      expandable: false,
      tabDensityMode: window.sap.m.IconTabDensityMode.Compact,
      backgroundDesign: window.sap.m.BackgroundDesign.Transparent,
      items: formData.map((formObject, index) => {
        const formName = formObject.namedForm ? formObject.name : pageKey;
        const oForm = forms[index];
        return component.set(window.sap.m.IconTabFilter, {
          name: `${formName}_tab_filter`,
          key: formName,
          text: formObject.title,
          customData: customData.set({
            name: `${formName}_select_handler`,
            key: "select_handler",
            value: { selectHandler: formObject.onSelect, formKey: formName }
          }),
          content: [oForm]
        });
      }),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("ft-transition-for-hide");
          comp.addStyleClass("noBottomLine");
        }
      }
    });
  } else if (formData.length === 1) {
    forms[0].setTitle("");
  }
  return formsWrapper !== void 0 ? [formsWrapper] : forms;
};
function refineEventForTable(oContent) {
  const onPressList = (0, import_lib_core2.tryit)(
    () => oContent.mEventRegistry.press || oContent.mEventRegistry.defaultAction
  ) || [];
  const isDefaultAction = (0, import_lib_core3.isTruthy)(oContent.mEventRegistry.defaultAction);
  const onPress = onPressList.length > 0 && onPressList[0].fFunction;
  const newOnPress = (oEvent) => {
    let oTable = oEvent.getSource().getParent().getParent();
    for (let idx = 0; idx < 3; idx += 1) {
      if (oTable.getMetadata().getName() === "sap.m.Table") {
        break;
      }
      oTable = oTable.getParent();
    }
    const oItems = oTable.getSelectedItems();
    const dataList = oItems.map((item) => item.getBindingContext().getObject());
    const selectedPaths = oTable.getSelectedContextPaths();
    const selectedIndices = selectedPaths.map(
      (each) => parseInt(each.replace("/items/", ""), 10)
    );
    const oPageNum = (0, import_use_pagination.getCustomObject)(oTable, "pageNum");
    const oVsDialog = (0, import_use_pagination.getCustomObject)(oTable, "view-settings-dialog");
    const usingPagination = !!oPageNum;
    onPress({
      oEvent,
      oTable,
      oItems,
      oVsDialog: oVsDialog && oVsDialog.getValue(),
      indices: selectedIndices,
      usingPagination,
      dataList,
      items: dataList
    });
  };
  if (onPressList.length > 0) {
    if (isDefaultAction) {
      oContent.detachDefaultAction(onPress);
      oContent.attachDefaultAction(void 0, newOnPress);
    } else {
      oContent.detachPress(onPress);
      oContent.attachPress(void 0, newOnPress);
    }
  }
}
function refineEventForTreeTable(oContent) {
  const onPressList = (0, import_lib_core2.tryit)(
    () => oContent.mEventRegistry.press || oContent.mEventRegistry.defaultAction
  ) || [];
  const isDefaultAction = (0, import_lib_core3.isTruthy)(oContent.mEventRegistry.defaultAction);
  const onPress = onPressList.length > 0 && onPressList[0].fFunction;
  const newOnPress = (oEvent) => {
    let oTable = oEvent.getSource();
    while (oTable.getMetadata().getName().includes("TreeTable") === false) {
      oTable = oTable.getParent();
    }
    const selectedIndices = oTable.getSelectedIndices();
    const oContexts = selectedIndices.map(
      (index) => oTable.getContextByIndex(index)
    );
    const dataList = oContexts.map((oCon) => oCon.getObject());
    const tableConfig = {
      dataList,
      indices: selectedIndices,
      usingPagination: false,
      items: dataList,
      oTable
    };
    onPress(__spreadValues({}, tableConfig));
  };
  if (onPressList.length > 0) {
    if (isDefaultAction) {
      oContent.detachDefaultAction(onPress);
      oContent.attachDefaultAction(void 0, newOnPress);
    } else {
      oContent.detachPress(onPress);
      oContent.attachPress(void 0, newOnPress);
    }
  }
}
function isSelectSingleMode(mode) {
  switch (mode) {
    case window.sap.m.ListMode.SingleSelectLeft:
    case window.sap.m.ListMode.SingleSelect:
    case window.sap.m.ListMode.SingleSelectMaster:
    case window.sap.ui.table.SelectionMode.Single:
      return true;
    default:
      return false;
  }
}
function isSelectMultipleMode(mode) {
  switch (mode) {
    case window.sap.m.ListMode.MultiSelect:
    case window.sap.ui.table.SelectionMode.MultiToggle:
      return true;
    default:
      return false;
  }
}
function createAndAddCustomData(targetComp, data, options = {}) {
  const { component } = options;
  const compId = targetComp.getId();
  Object.entries(data).forEach(([key, value]) => {
    targetComp.addCustomData(
      component.set(window.sap.ui.core.CustomData, {
        name: `${compId}-${key}-storage`,
        key,
        value
      })
    );
  });
}
function usePagination(oTable, options = {}) {
  const { component, each } = options;
  const tableId = oTable.getId();
  const pageItems = component.set(window.sap.m.HBox, {
    name: `${tableId}-pages`,
    items: {
      path: "/items",
      template: component.set(window.sap.m.Button, {
        name: `${tableId}-page`,
        text: "{text}",
        type: window.sap.m.ButtonType.Transparent,
        press: (oEvent) => {
          const pressedNumber = oEvent.getSource().getText();
          (0, import_use_pagination.default)(oTable, each, pressedNumber).onPress();
        }
      })
    },
    callback: (comp, isInitial, setData) => {
      const indexInfo = component.set(window.sap.m.Text, {
        name: `${tableId}-index-info`
        // busyIndicatorDelay: 0
      });
      createAndAddCustomData(indexInfo, { index: true }, { component });
      oTable.getHeaderToolbar().addContent(indexInfo);
      setData({ items: [] });
    }
  });
  const oPagination = component.set(window.sap.m.Toolbar, {
    name: `${tableId}-pagination`,
    content: [
      component.set(window.sap.m.ToolbarSpacer, {
        name: `${pageItems.getId()}-wrap-front`
      }),
      pageItems,
      component.set(window.sap.m.ToolbarSpacer, {
        name: `${pageItems.getId()}-wrap-end`
      })
    ],
    callback: (comp, isInitial) => {
      if (isInitial) {
        comp.setVisible(false);
        comp.addStyleClass("ft-pagination");
      }
    }
  });
  const oPageNum = (0, import_use_pagination.getCustomObject)(oTable, "pageNum");
  if (oPageNum) {
    (0, import_use_pagination.default)(oTable, each, oPageNum.getValue()).onInitial(pageItems);
  } else {
    createAndAddCustomData(oTable, { pageNum: 1 }, { component });
    (0, import_use_pagination.default)(oTable, each).onInitial(pageItems);
  }
  return [oTable, oPagination];
}
function focusBackInTable(oTable, { component }) {
  setTimeout(function() {
    console.log(
      "set focus:",
      window.currentFocusRowIndex,
      window.currentFocusColIndex,
      window.currentFocusInputName,
      window.currentFocusTableName
    );
    if (window.currentFocusRowIndex !== void 0 && window.currentFocusColIndex !== void 0) {
      if (window.currentFocusTableName === oTable.getId()) {
        const currentFocusRowIndex = window.currentFocusRowIndex;
        const currentFocusColIndex = window.currentFocusColIndex;
        window.currentFocusRowIndex = void 0;
        window.currentFocusColIndex = void 0;
        if (oTable.getMetadata().getName() === "sap.ui.table.TreeTable") {
          const oItem = oTable.getRows()[currentFocusRowIndex];
          if (oItem) {
            const oCell = oItem.getCells()[currentFocusColIndex];
            oCell.focus();
            oCell.selectText(0, oCell.getValue().length);
          }
        } else {
          const oItem = oTable.getItems()[currentFocusRowIndex];
          if (oItem) {
            const oCell = oItem.getCells()[currentFocusColIndex];
            oCell.focus();
            oCell.selectText(0, oCell.getValue().length);
          }
        }
      }
    } else if (window.currentFocusInputName !== void 0) {
      const currentFocusInputName = window.currentFocusInputName;
      window.currentFocusInputName = void 0;
      const oInput = component.get(currentFocusInputName);
      if (oInput) {
        oInput.focus();
        oInput.selectText(0, oInput.getValue().length);
      }
    }
  }, 0);
}
const getTableProperties = (tableName, each, { oCells }, { component, getFieldWithFactory, column, text, columnListItem }) => {
  let mode = each.mode || each.properties.mode;
  if ([
    window.sap.m.ListMode.SingleSelect,
    window.sap.m.ListMode.SingleSelectMaster
  ].includes(mode)) {
    mode = window.sap.m.ListMode.SingleSelectLeft;
  } else if (mode) {
    if (["s", "single"].includes(mode.toLowerCase())) {
      mode = window.sap.m.ListMode.SingleSelectLeft;
    } else if (["m", "multi", "multiple"].includes(mode.toLowerCase())) {
      mode = window.sap.m.ListMode.MultiSelect;
    }
  }
  const tblSorters = [];
  if (each.groupKey) {
    const grouper = (oContext) => {
      const txtKey = oContext.getProperty(each.groupKey);
      const txtText = oContext.getProperty(each.groupText || each.groupKey);
      return {
        key: txtKey,
        text: txtText
      };
    };
    const groupSorter = new window.sap.ui.model.Sorter(
      each.groupKey,
      false,
      grouper
    );
    tblSorters.push(groupSorter);
  }
  const useDragDrop = each.onDrop ? [
    component.set(window.sap.ui.core.dnd.DragDropInfo, {
      name: "TABLE_DRAG_DROP_INFO",
      sourceAggregation: "items",
      targetAggregation: "items",
      dropPosition: "Between",
      drop: each.onDrop,
      enabled: each.enabled || false
    })
  ] : null;
  return {
    name: tableName,
    dragDropConfig: useDragDrop,
    settings: __spreadProps(__spreadValues({
      autoPopinMode: !each.allowScroll,
      fixedLayout: false,
      alternateRowColors: true,
      popinLayout: window.sap.m.PopinLayout.GridSmall,
      width: "auto",
      growing: true,
      growingThreshold: 300,
      sticky: [
        window.sap.m.Sticky.ColumnHeaders,
        window.sap.m.Sticky.HeaderToolbar,
        window.sap.m.Sticky.InfoToolbar
      ]
    }, each.properties), {
      mode,
      headerToolbar: component.set(window.sap.m.Toolbar, {
        name: `${tableName}_header_toolbar`,
        settings: __spreadValues({
          visible: (0, import_lib_core3.isTruthy)(each.toolbar),
          design: window.sap.m.ToolbarDesign.Transparent
        }, (0, import_lib_core3.isTruthy)(each.toolbar) && __spreadProps(__spreadValues({}, each.toolbar.properties), {
          content: each.toolbar.content.map((bar) => {
            const oContent = getFieldWithFactory(
              `${tableName}_bar_ct_${bar.name}`,
              bar
            );
            if (oContent.getMetadata().getName() === "sap.m.MenuButton") {
              oContent.getMenu().getItems().forEach((oItem) => {
                refineEventForTable(oItem);
              });
              if (oContent.getButtonMode() === window.sap.m.MenuButtonMode.Split) {
                if (bar.component.properties.defaultAction) {
                  refineEventForTable(oContent);
                }
              }
            } else {
              refineEventForTable(oContent);
            }
            return oContent;
          })
        })),
        callback: (comp, isInitial) => {
          if (isInitial) {
            comp.addStyleClass("sapUiSizeCompact");
          }
        }
      }),
      // fieldGroupIds: ["toggleFixedLayout"],
      columns: {
        path: "/columns/",
        template: column.set({
          name: `${tableName}_columns`,
          settings: __spreadValues({
            header: text.set({
              name: `${tableName}_column_header`,
              settings: __spreadProps(__spreadValues({}, each.headerNoWrap === true ? {
                maxLines: 1,
                emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On
              } : {}), {
                text: "{text}"
              })
            }),
            hAlign: "{hAlign}"
          }, each.columnProperties)
        })
      },
      items: {
        path: "/items/",
        sorter: tblSorters,
        template: columnListItem.set({
          name: `${tableName}_items`,
          settings: __spreadProps(__spreadValues({
            vAlign: window.sap.ui.core.VerticalAlign.Middle
          }, each.items.properties), {
            cells: oCells
          })
        })
      },
      selectionChange: (oEvent) => {
        const oTable = oEvent.getSource();
        const mode2 = oTable.getMode();
        const params = oEvent.getParameters();
        const selectedPaths = oTable.getSelectedContextPaths();
        const selectedIndices = selectedPaths.map(
          (eachPath) => parseInt(eachPath.replace("/items/", ""), 10)
        );
        const usingPagination = !!each.usePagination;
        if (isSelectMultipleMode(mode2)) {
          const selectedItems = params.listItems;
          const selectedData = selectedItems.map(
            (item) => item.getBindingContext().getObject()
          );
          !usingPagination && each.onSelect && each.onSelect({
            oEvent,
            oTable,
            oItems: selectedItems,
            indices: selectedIndices,
            usingPagination,
            // allIndices: oIndices,
            dataList: selectedData,
            items: selectedData,
            selected: params.selected
          });
        } else if (isSelectSingleMode(mode2)) {
          const selectedItem = params.listItem;
          const selectedData = selectedItem.getBindingContext().getObject();
          (!usingPagination || each.allowOnSelect) && each.onSelect && each.onSelect({
            oEvent,
            oTable,
            oItems: [selectedItem],
            indices: selectedIndices,
            usingPagination,
            dataList: [selectedData],
            items: [selectedData],
            selected: params.selected
          });
        }
      }
    }),
    callback: (comp, isInitial, setData) => {
      focusBackInTable(comp, { component });
      comp.addStyleClass("select-all");
      comp.addStyleClass("no-indent-row");
      if (each.compactSize === true) {
        comp.addStyleClass("sapUiSizeCompact");
      }
      if (each.allowScroll === true) {
        comp.addStyleClass("allow-scroll");
      }
      const mapConv = {};
      const newColumns = each.columns.map((col) => {
        if (col.conv) {
          mapConv[col.name] = col.conv;
        }
        if (col.showSum === true) {
          let unit = col.component.properties.unit;
          let unitIsBound = false;
          if ((0, import_functions.isBound)(unit)) {
            unit = unit.replace("{", "").replace("}", "");
            unitIsBound = true;
          }
          col.footerText = each.items.list.reduce((acc, item) => {
            if (unitIsBound) {
              if (acc[item[unit]] === void 0) {
                acc[item[unit]] = 0;
              }
              acc[item[unit]] += parseFloat(item[col.name]);
            } else {
              if (acc[unit] === void 0) {
                acc[unit] = 0;
              }
              acc[unit] += parseFloat(item[col.name]);
            }
            return acc;
          }, {});
          col.footerText = Object.keys(col.footerText).map((key) => {
            return [
              (0, import_fieldComponent.convertNumber)(col.footerText[key], key, {
                isAmount: col.component.properties.isAmount,
                isQuantity: col.component.properties.isQuantity,
                asA1: col.component.properties.asA1
              }),
              key
            ].join(" ");
          }).join("\n");
        }
        if ((0, import_lib_core2.tryit)(() => col.component.type) === "ObjectNumber") {
          col.hAlign = window.sap.ui.core.TextAlign.End;
        }
        return col;
      });
      const data = {
        columns: newColumns,
        items: each.items.list.map((item) => {
          const newItem = __spreadValues({}, item);
          Object.keys(mapConv).forEach((colName) => {
            newItem[colName] = getNewValue(newItem[colName], mapConv[colName]);
          });
          if (!newItem.properties) {
            newItem.properties = {};
          }
          if (!newItem.properties.row) {
            newItem.properties.row = {};
          }
          if (!newItem.properties.cell) {
            newItem.properties.cell = Object.keys(item || {}).filter(
              (key) => !["properties", "selected", "selectable"].includes(key)
            ).reduce((acc, key) => {
              acc[key] = {};
              return acc;
            }, {});
          }
          return newItem;
        })
      };
      each.columns.forEach((col) => {
        const colComponents = (0, import_lib_core3.isArray)(col.component) ? col.component : [col.component];
        colComponents.forEach((colComp) => {
          const list = (0, import_lib_core2.tryit)(() => colComp.list) || (0, import_lib_core2.tryit)(() => colComp.items.list);
          if (list) {
            data[[tableName, col.name, colComp.name, "items"].filter(Boolean).join("-")] = list.map((each2) => {
              if ((0, import_lib_core3.isObject)(each2)) {
                return each2;
              } else {
                return { key: each2, text: each2 };
              }
            });
          }
        });
      });
      setData(data);
      timeouts[`${tableName}-data`] = data;
      if (["sap.m.Table", "sap.ui.table.TreeTable"].includes(
        comp.getMetadata().getName()
      )) {
        const timeOutId = `${tableName}-1`;
        if (timeouts[timeOutId]) {
          clearTimeout(timeouts[timeOutId]);
          delete timeouts[timeOutId];
        }
        timeouts[timeOutId] = setTimeout(function() {
          comp.getItems().filter((oItem) => oItem.getCells).forEach((oItem, idx) => {
            oItem.getCells().forEach((oCell) => {
              const bindPath = (0, import_lib_core2.tryit)(
                () => oCell.getBindingInfo("value").binding.sPath
              );
              if (bindPath) {
                const internalValue = timeouts[`${tableName}-data`].items[idx][bindPath];
                if (!internalValue) {
                  const renderedValue = oCell.getValue();
                  if (renderedValue && renderedValue !== internalValue) {
                    oCell.setValue(internalValue);
                  }
                }
              }
            });
          });
        });
      }
      {
        const timeOutId = `${tableName}-2`;
        if (timeouts[timeOutId]) {
          clearTimeout(timeouts[timeOutId]);
          delete timeouts[timeOutId];
        }
        timeouts[timeOutId] = setTimeout(function() {
          const oPageNum = (0, import_use_pagination.getCustomObject)(comp, "pageNum");
          const pageNumStr = oPageNum && oPageNum.getValue();
          const pageNum = pageNumStr && parseInt(pageNumStr, 10);
          const mode2 = comp.getMode();
          if (mode2 !== window.sap.m.ListMode.None) {
            comp.getItems().forEach((oItem, idx) => {
              const itemIdx = each.usePagination ? idx + (pageNum - 1) * each.usePagination.itemsPerPage : idx;
              comp.setSelectedItemById(
                oItem.getId(),
                !!(0, import_lib_core2.tryit)(
                  () => timeouts[`${tableName}-data`].items[itemIdx].selected,
                  false
                )
              );
            });
          }
        });
      }
    }
  };
};
const getTreeTableProperties = (tableName, each, { oCells }, { text, component, getFieldWithFactory }) => {
  let mode = each.mode || each.properties.mode || "";
  if ([
    window.sap.ui.table.SelectionMode.Multi.toLowerCase(),
    "m",
    "multiple"
  ].includes(mode.toLowerCase())) {
    mode = window.sap.ui.table.SelectionMode.MultiToggle;
  } else if ([
    window.sap.ui.table.SelectionMode.Single.toLowerCase(),
    "s",
    "single"
  ].includes(mode.toLowerCase())) {
    mode = window.sap.ui.table.SelectionMode.Single;
  } else {
    mode = window.sap.ui.table.SelectionMode.None;
  }
  const useDragDrop = each.onDrop ? [
    component.set(window.sap.ui.core.dnd.DragDropInfo, {
      name: "TREE_TABLE_DRAG_DROP_INFO",
      sourceAggregation: "rows",
      targetAggregation: "rows",
      dropPosition: "OnOrBetween",
      drop: each.onDrop,
      enabled: each.enabled || false
    })
  ] : null;
  each.alterArrayNames = each.alterArrayNames === void 0 ? [] : each.alterArrayNames;
  return {
    name: tableName,
    enableSelectAll: false,
    selectionBehavior: window.sap.ui.table.SelectionBehavior.Row,
    dragDropConfig: useDragDrop,
    extension: component.set(window.sap.m.Toolbar, {
      name: `${tableName}_header_toolbar`,
      settings: __spreadValues({
        visible: (0, import_lib_core3.isTruthy)(each.toolbar),
        design: window.sap.m.ToolbarDesign.Solid
      }, (0, import_lib_core3.isTruthy)(each.toolbar) && __spreadProps(__spreadValues({}, each.toolbar.properties), {
        content: each.toolbar.content.map((bar) => {
          const oContent = getFieldWithFactory(
            `${tableName}_bar_ct_${bar.name}`,
            bar
          );
          if (oContent.getMetadata().getName() === "sap.m.MenuButton") {
            oContent.getMenu().getItems().forEach((oItem) => {
              refineEventForTreeTable(oItem);
            });
            if (oContent.getButtonMode() === window.sap.m.MenuButtonMode.Split) {
              if (bar.component.properties.defaultAction) {
                refineEventForTreeTable(oContent);
              }
            }
          } else {
            refineEventForTreeTable(oContent);
          }
          return oContent;
        })
      })),
      callback: (comp, isInitial) => {
        if (isInitial) {
          comp.addStyleClass("sapUiSizeCompact");
        }
      }
    }),
    selectionMode: mode,
    columns: each.columns.map((col, cIdx) => {
      return component.set(window.sap.ui.table.Column, {
        name: `${tableName}_column_${col.name}`,
        settings: __spreadProps(__spreadValues({
          label: text.set({
            name: `${tableName}_column_header_${col.name}`,
            settings: __spreadProps(__spreadValues({}, each.headerNoWrap === true ? {
              maxLines: 1,
              emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On
            } : {}), {
              text: col.text
            })
          }),
          hAlign: col.hAlign
        }, col.properties), {
          template: oCells[cIdx]
        })
      });
    }),
    rows: {
      path: "/items",
      parameters: {
        arrayNames: ["$sub$", ...each.alterArrayNames],
        numberOfExpandedLevels: 1
      }
    },
    toggleOpenState: (oEvent) => {
      const oComp = oEvent.getSource();
      oComp.setVisibleRowCount(oComp._getTotalRowCount());
    },
    rowsUpdated: (oEvent) => {
      const oComp = oEvent.getSource();
      oComp._getPointerExtension()._detachEvents();
    },
    // Interactive로 해야 동적으로 visibleRowCount를 수정할수 있음.
    visibleRowCountMode: window.sap.ui.table.VisibleRowCountMode.Interactive,
    // 컬럼 사이즈를 조절하는 기능과 같은 맥락으로 컬럼 위치를 옮기는 기능도 무력화.
    enableColumnReordering: false,
    callback: (oComp, isInitial, setData) => {
      oComp._getScrollExtension().isVerticalScrollbarRequired = () => false;
      const data = {
        items: each.items.list
      };
      setData(data);
      oComp.setVisibleRowCount(oComp._getTotalRowCount());
      if (each.stickyColumns === true) {
        oComp.addStyleClass("ft-sticky-columns");
      }
      focusBackInTable(oComp, { component });
    }
  };
};
const getObjectPageProperties = (tableName, each, { oCells }, { component, getFieldWithFactory, column, text, columnListItem, hBox }) => {
  const params = getTableProperties(
    tableName,
    each,
    { oCells },
    { component, getFieldWithFactory, column, text, columnListItem }
  );
  oCells.forEach((oComp) => {
    (0, import_lib_core2.tryit)(() => oComp.addStyleClass("sapUiTinyMargin"));
  });
  delete params.settings.columns;
  params.settings.items.template = component.set(window.sap.m.CustomListItem, {
    name: `${tableName}_items`,
    settings: __spreadProps(__spreadValues({}, each.items.properties), {
      content: hBox.set({
        name: `${tableName}_items_cont`,
        wrap: window.sap.m.FlexWrap.Wrap,
        // justifyContent: window.sap.m.FlexJustifyContent.FlexStart,
        items: oCells
      })
    })
  });
  return params;
};
const buildTables = (column, text, columnListItem, component, hBox, vBox, customData) => (tableData, { pageKey, getFieldWithFactory, wrapSingleTable }) => {
  const tables = tableData.map((each) => {
    let showFooter = false;
    each.columns.every((col) => {
      if (col.showSum === true) {
        showFooter = true;
        return false;
      }
      return true;
    });
    if (showFooter) {
      each.columnProperties.footer = text.set({
        name: "column_footer",
        settings: {
          text: "{footerText}"
        }
      });
    }
    const tableName = [each.name, pageKey].join("-");
    const oCells = each.columns.map((col) => {
      const cellName = getNewName([tableName, col.name].join("-"));
      if ((0, import_lib_core2.tryit)(() => col.component.type) === "ObjectNumber" && col.hideOnInitial === true) {
        return hBox.set({
          name: cellName,
          justifyContent: window.sap.m.FlexJustifyContent.End,
          items: [
            getFieldWithFactory(cellName, __spreadProps(__spreadValues({}, col), {
              component: __spreadProps(__spreadValues({}, col.component), {
                properties: __spreadProps(__spreadValues({}, col.component.properties), {
                  visible: `{= \${${col.name}} === '0.00' ? false : true}`
                })
              })
            })),
            getFieldWithFactory(cellName, {
              component: {
                type: "Text",
                properties: {
                  visible: `{= \${${col.name}} === '0.00' ? true : false}`
                }
              }
            })
          ]
        });
      }
      if ((0, import_lib_core3.isArray)(col.component)) {
        return (col.vertical ? vBox : hBox).set(
          (0, import_functions.removeUndefinedKeys)({
            name: cellName,
            width: col.width,
            layoutData: component.set(
              window.sap.m.FlexItemData,
              (0, import_functions.removeUndefinedKeys)({
                name: "wrapLayout-" + cellName,
                // baseSize: "100%",
                maxWidth: col.maxWidth,
                minWidth: col.minWidth
              })
            ),
            items: col.component.map((comp) => {
              return getFieldWithFactory(
                [cellName, comp.name].filter(Boolean).join("-"),
                __spreadProps(__spreadValues({}, col), {
                  value: comp.name ? `{${comp.name}}` : col.value,
                  component: comp
                })
              );
            })
          })
        );
      }
      return getFieldWithFactory(cellName, __spreadProps(__spreadValues({}, col), {
        component: __spreadProps(__spreadValues({}, col.component), {
          properties: __spreadProps(__spreadValues({}, col.component && col.component.properties), {
            parentTableId: tableName
          })
        })
      }));
    });
    const oTable = each.isTreeTable === true ? component.set(
      window.sap.ui.table.TreeTable,
      getTreeTableProperties(
        tableName,
        each,
        { oCells },
        { text, component, getFieldWithFactory }
      )
    ) : each.isObjects === true ? component.set(
      window.sap.m.List,
      getObjectPageProperties(
        tableName,
        each,
        { oCells },
        {
          component,
          getFieldWithFactory,
          column,
          text,
          columnListItem,
          hBox
        }
      )
    ) : component.set(
      window.sap.m.Table,
      getTableProperties(
        tableName,
        each,
        { oCells },
        { component, getFieldWithFactory, column, text, columnListItem }
      )
    );
    const searchHandler = (0, import_lib_core2.tryit)(
      () => each.toolbar.content.find(
        ({ component: component2 }) => component2.type === "SearchField"
      )
    );
    if (searchHandler && !searchHandler.component.properties.search) {
      const oSearch = oTable.getHeaderToolbar().getContent().find((item) => item.getMetadata().getName() === "sap.m.SearchField");
      const oldInput = oSearch.getValue();
      createAndAddCustomData(oSearch, { search: oldInput }, { component });
      (0, import_use_default_searcher.default)(oSearch, each);
    }
    if (each.toolbar && each.toolbar.useViewSettings) {
      const vsName = `view-settings-dialog-${tableName}`;
      const vsDialog = {
        name: vsName,
        confirm: (oEvent) => {
          const mParams = oEvent.getParameters();
          if (mParams.sortItem === void 0) {
            return;
          }
          const sPath = mParams.sortItem.getKey();
          each.onSort({
            sorter: (0, import_lib_core.createSorter)(
              [sPath],
              mParams.sortDescending ? "desc" : "asc"
            )
          });
        }
      };
      vsDialog.sortItems = each.columns.reduce((acc, col) => {
        acc.push(
          component.set(window.sap.m.ViewSettingsItem, {
            name: `vsitem-${tableName}-${col.name}`,
            key: col.name,
            text: col.text
          })
        );
        if ((0, import_lib_core3.isArray)(col.component)) {
          col.component.forEach((comp) => {
            if (col.name !== comp.name && comp.textOnSort) {
              acc.push(
                component.set(window.sap.m.ViewSettingsItem, {
                  name: `vsitem-${tableName}-${comp.name}`,
                  key: comp.name,
                  text: comp.textOnSort
                })
              );
            }
          });
        }
        return acc;
      }, []);
      const oVsDialog = component.set(
        window.sap.m.ViewSettingsDialog,
        vsDialog
      );
      createAndAddCustomData(
        oTable,
        { "view-settings-dialog": oVsDialog },
        { component }
      );
    }
    const tableBox = component.set(window.sap.m.VBox, {
      name: `vertical-box-${tableName}`,
      items: each.usePagination ? usePagination(oTable, { component, each }) : oTable
    });
    if (each.allowScroll) {
      const scrollContainerName = ["container", tableName].join("_");
      return component.set(window.sap.m.ScrollContainer, {
        name: scrollContainerName,
        content: tableBox,
        vertical: true,
        height: "100%",
        callback: (comp) => {
          comp.addStyleClass("allow-scroll");
          comp.setLayoutData(
            component.set(window.sap.m.FlexItemData, {
              name: `${scrollContainerName}_layout`,
              styleClass: "allow-scroll"
            })
          );
        }
      });
    } else {
      return tableBox;
    }
  });
  let tablesWrapper;
  if (tableData.length > 1 || wrapSingleTable === true) {
    const isCozy = tableData.reduce((acc, tableObject) => {
      if (acc) {
        return acc;
      }
      if (tableObject.count !== void 0 && tableObject.icon === void 0 && tableObject.title) {
        return true;
      }
      return false;
    }, false);
    const hasIcon = tableData.reduce((acc, tableObject) => {
      if (acc) {
        return acc;
      }
      if (tableObject.icon) {
        return true;
      }
      return false;
    }, false);
    tablesWrapper = component.set(window.sap.m.IconTabBar, {
      name: ["tablesWrapper", pageKey].join("_"),
      // applyContentPadding: false,
      headerMode: window.sap.m.IconTabHeaderMode.Inline,
      expandable: false,
      select: (oEvent) => {
        const oItem = oEvent.getParameters().item;
        const customDataList = oItem.getCustomData();
        const oCustomData = customDataList.find(
          (each) => each.getKey() === "select_handler"
        );
        if (oCustomData) {
          const { selectHandler, tableKey } = oCustomData.getValue();
          if (selectHandler) {
            selectHandler({ tableKey });
          }
        }
      },
      tabDensityMode: isCozy ? window.sap.m.IconTabDensityMode.Cozy : window.sap.m.IconTabDensityMode.Compact,
      // headerMode: window.sap.m.IconTabHeaderMode.Inline,
      backgroundDesign: window.sap.m.BackgroundDesign.Transparent,
      items: tableData.map((tableObject, index) => {
        const name = tableObject.name;
        const oTable = tables[index];
        return component.set(window.sap.m.IconTabFilter, {
          name: `${name}_tab_filter`,
          key: name,
          text: tableObject.title,
          icon: tableObject.icon,
          customData: customData.set({
            name: `${name}_select_handler`,
            key: "select_handler",
            value: { selectHandler: tableObject.onSelectTab, tableKey: name }
          }),
          count: tableObject.count === void 0 ? void 0 : tableObject.count.toString(),
          design: hasIcon ? window.sap.m.IconTabFilterDesign.Horizontal : window.sap.m.IconTabFilterDesign.Vertical,
          content: [oTable]
        });
      }),
      callback: (comp, isInitial) => {
        comp.addStyleClass("ft-selected-icon-tab");
        comp.addStyleClass("noBottomLine");
      }
    });
  }
  return tablesWrapper !== void 0 ? [tablesWrapper] : tables;
};
function getNewValue(originValue, convAlias) {
  const convRoutine = import_lib_core2.conv[convAlias] ? import_lib_core2.conv[convAlias].out : void 0;
  if (convRoutine) {
    return convRoutine(originValue);
  } else {
    return originValue;
  }
}
const getField = (factory, fn) => (name, field) => {
  const {
    component,
    button,
    text,
    input,
    link,
    select,
    customData,
    checkbox,
    dialog,
    vBox,
    hBox
  } = factory;
  const type = (0, import_lib_core2.defined)(
    (0, import_lib_core2.tryit)(() => field.component.type),
    "Text"
  );
  const properties = (0, import_functions.refineProps)(
    (0, import_lib_core2.tryit)(() => field.component.properties),
    fn
  );
  const hiddenData = (0, import_lib_core2.tryit)(() => field.component.hiddenData);
  const label = (0, import_lib_core2.tryit)(() => field.component.label);
  const styleClasses = (0, import_lib_core2.tryit)(() => field.component.styleClasses) || [];
  const newValue = getNewValue(field.value, field.conv);
  switch (type) {
    case "ObjectNumber": {
      const oComp = import_fieldComponent.fieldComponent.ObjectNumber(
        component,
        text
      )({
        name,
        value: newValue,
        properties: __spreadProps(__spreadValues({}, properties), {
          textAlign: window.sap.ui.core.TextAlign.End
        })
      });
      if (field.hideUnit === true) {
        oComp.addStyleClass("hideUnit");
      }
      return oComp;
    }
    case "ObjectAttribute": {
      return import_fieldComponent.fieldComponent.ObjectAttribute(component)({
        name,
        value: newValue,
        title: field.component.title,
        properties
      });
    }
    case "ObjectIdentifier": {
      return import_fieldComponent.fieldComponent.ObjectIdentifier(component)({
        name,
        value: newValue,
        text: field.component.text,
        normalWeight: field.component.normalWeight,
        properties
      });
    }
    case "ObjectStatus": {
      return import_fieldComponent.fieldComponent.ObjectStatus(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "ToolbarSpacer": {
      return import_fieldComponent.fieldComponent.ToolbarSpacer(component)({
        name,
        properties
      });
    }
    case "HTML": {
      return import_fieldComponent.fieldComponent.HTML(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "PDFViewer": {
      return import_fieldComponent.fieldComponent.PDFViewer(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "DateTime": {
      return import_fieldComponent.fieldComponent.DateTime(component)({
        name,
        value: newValue,
        format: field.component.format,
        displayFormat: field.component.displayFormat,
        properties
      });
    }
    case "Date": {
      return import_fieldComponent.fieldComponent.Date(component)({
        name,
        value: newValue,
        format: field.component.format,
        displayFormat: field.component.displayFormat,
        properties
      });
    }
    case "Time": {
      return import_fieldComponent.fieldComponent.Time(component)({
        name,
        value: newValue,
        format: field.component.format,
        displayFormat: field.component.displayFormat,
        properties
      });
    }
    case "DateRange": {
      return import_fieldComponent.fieldComponent.DateRange(component)({
        name,
        value: newValue,
        format: field.component.format,
        displayFormat: field.component.displayFormat,
        properties
      });
    }
    case "Radio": {
      return import_fieldComponent.fieldComponent.Radio(
        component,
        customData
      )({
        name,
        value: newValue,
        properties,
        list: field.component.list
      });
    }
    case "SegmentedButton": {
      return import_fieldComponent.fieldComponent.SegmentedButton(
        component,
        vBox
      )({
        name,
        label,
        value: newValue,
        properties,
        list: field.component.list
      });
    }
    case "NotificationListItem": {
      return import_fieldComponent.fieldComponent.NotificationListItem(
        component,
        vBox
      )({
        name,
        label,
        value: newValue,
        properties
      });
    }
    case "ComboBox": {
      const sorter = (0, import_lib_core2.tryit)(() => field.component.sorter, []);
      const itemProperties = (0, import_lib_core2.tryit)(() => field.component.items.properties, {});
      const itemData = (0, import_lib_core2.tryit)(() => field.component.items.list, []);
      const multiple = (0, import_lib_core2.tryit)(() => field.component.multiple, false);
      return import_fieldComponent.fieldComponent.ComboBox(component)({
        name,
        value: newValue,
        properties,
        sorter,
        itemProperties,
        itemData,
        multiple
      });
    }
    case "MenuButton": {
      return import_fieldComponent.fieldComponent.MenuButton(
        component,
        fn
      )({
        name,
        value: newValue,
        items: field.items,
        properties,
        getFieldWithFactory: getField(factory, fn)
      });
    }
    case "Menu": {
      return import_fieldComponent.fieldComponent.Menu(
        component,
        fn
      )({
        name,
        items: field.items,
        getFieldWithFactory: getField(factory, fn)
      });
    }
    case "MenuItem": {
      return import_fieldComponent.fieldComponent.MenuItem(
        component,
        fn
      )({
        name,
        value: newValue,
        items: field.items,
        confirmMessage: field.component.confirmMessage,
        properties,
        getFieldWithFactory: getField(factory, fn)
      });
    }
    case "SearchField": {
      return import_fieldComponent.fieldComponent.SearchField(
        component,
        fn
      )({
        name,
        value: newValue,
        properties
      });
    }
    case "Button": {
      properties.type = (0, import_lib_core2.defined)(properties.type, window.sap.m.ButtonType.Ghost);
      const isScannerButton = (0, import_lib_core2.defined)(
        (0, import_lib_core2.tryit)(() => field.component.isScannerButton),
        false
      );
      const onScan = (0, import_functions.refineProps)({ onScan: field.component.onScan }, fn).onScan;
      const onError = (0, import_functions.refineProps)({ onError: field.component.onError }, fn).onError;
      return import_fieldComponent.fieldComponent.Button(
        button,
        component
      )({
        name,
        value: newValue,
        number: field.component.number,
        confirmMessage: field.component.confirmMessage,
        key: field.key,
        isScannerButton,
        onScan,
        onError,
        properties
      });
    }
    case "Input": {
      const multiple = (0, import_lib_core2.tryit)(() => field.component.multiple, false);
      const isAmount = (0, import_lib_core2.tryit)(() => field.component.isAmount, false);
      const isQuantity = (0, import_lib_core2.tryit)(() => field.component.isQuantity, false);
      const unit = (0, import_lib_core2.tryit)(() => field.component.unit);
      const items = (0, import_lib_core2.tryit)(() => field.component.items, false);
      return import_fieldComponent.fieldComponent.Input(
        input,
        component,
        dialog,
        customData
      )({
        name,
        value: newValue,
        properties,
        refineProps: import_functions.refineProps,
        fn,
        multiple,
        isAmount,
        isQuantity,
        unit,
        items
      });
    }
    case "Select": {
      const itemProperties = (0, import_lib_core2.tryit)(() => field.component.items.properties, {});
      const itemData = (0, import_lib_core2.tryit)(() => field.component.items.list, []);
      return import_fieldComponent.fieldComponent.Select(
        select,
        component
      )({
        name,
        value: newValue,
        itemProperties,
        properties,
        itemData
      });
    }
    case "Link": {
      return import_fieldComponent.fieldComponent.Link(link)({
        name,
        value: newValue,
        properties
      });
    }
    case "List": {
      const itemProperties = (0, import_lib_core2.tryit)(() => field.component.items.properties, {});
      const itemData = (0, import_lib_core2.tryit)(() => field.component.items.list, []);
      return import_fieldComponent.fieldComponent.List(
        component,
        fn
      )({ name, properties, itemProperties, itemData });
    }
    case "Text": {
      return import_fieldComponent.fieldComponent.Text(
        text,
        customData
      )({
        name,
        value: newValue,
        styleClasses,
        properties
      });
    }
    case "Switch": {
      return import_fieldComponent.fieldComponent.Switch(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "TextArea": {
      return import_fieldComponent.fieldComponent.TextArea(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "GenericTile": {
      return import_fieldComponent.fieldComponent.GenericTile(
        component,
        customData
      )({
        name,
        value: newValue,
        properties
      });
    }
    case "ExpandableText": {
      return import_fieldComponent.fieldComponent.ExpandableText(
        component,
        customData
      )({
        name,
        value: newValue,
        properties
      });
    }
    case "MessageStrip": {
      return import_fieldComponent.fieldComponent.MessageStrip(
        component,
        customData
      )({
        name,
        value: newValue,
        properties
      });
    }
    case "FormattedText": {
      return import_fieldComponent.fieldComponent.FormattedText(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "CodeEditor": {
      return import_fieldComponent.fieldComponent.CodeEditor(
        component,
        button,
        vBox,
        customData,
        fn
      )({
        name,
        value: newValue,
        properties,
        getFieldWithFactory: getField(factory, fn)
      });
    }
    case "Separator": {
      return import_fieldComponent.fieldComponent.Separator(component)({
        name,
        properties
      });
    }
    case "CheckBox": {
      return import_fieldComponent.fieldComponent.CheckBox(
        checkbox,
        customData
      )({
        name,
        value: newValue,
        hiddenData,
        styleClasses,
        properties
      });
    }
    case "FileUploader": {
      return import_fieldComponent.fieldComponent.FileUploader(component)({
        name,
        properties
      });
    }
    case "Image": {
      return import_fieldComponent.fieldComponent.Image(
        component,
        hBox,
        customData
      )({
        name,
        value: newValue,
        bindArray: (0, import_lib_core2.tryit)(() => field.component.bindArray),
        hiddenData,
        properties
      });
    }
    case "Label": {
      return import_fieldComponent.fieldComponent.Label(component)({
        name,
        value: newValue,
        properties
      });
    }
    case "StepInput": {
      return import_fieldComponent.fieldComponent.StepInput(
        component,
        vBox,
        customData
      )({
        name,
        value: newValue,
        label,
        hiddenData,
        properties
      });
    }
    default: {
      break;
    }
  }
};
