var __create = Object.create;
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var modules_exports = {};
__export(modules_exports, {
  ComponentsList: () => ComponentsList,
  SectionsList: () => SectionsList,
  TypeList: () => TypeList,
  addMetaTable: () => addMetaTable,
  extractFunctions: () => extractFunctions,
  getBindParams: () => getBindParams,
  getCombinedKeyString: () => getCombinedKeyString,
  getDefaultPathValue: () => getDefaultPathValue,
  getFunctionHeader: () => getFunctionHeader,
  getMixedOptions: () => getMixedOptions,
  getOptionTarget: () => getOptionTarget,
  getOptionsOnDialog: () => getOptionsOnDialog,
  getOtherContentKeys: () => getOtherContentKeys,
  getParentObjects: () => getParentObjects,
  getSubKeys: () => getSubKeys,
  getTargetArray: () => getTargetArray,
  getTargetObj: () => getTargetObj,
  metaKeyStucture: () => metaKeyStucture,
  replaceInvalidChar: () => replaceInvalidChar
});
module.exports = __toCommonJS(modules_exports);
var import_lib_core = require("@bsgp/lib-core");
var import_form_and_table = require("@bsgp/form-and-table");
var import_mods = require("../mods");
var constants = __toESM(require("./forms"));
const commonOptions = [
  "properties-width",
  "properties-enabled",
  "properties-height"
];
const optsPerTypes = {
  forms: ["table"],
  fields: [],
  tables: [
    "items",
    "closeDialogOnSelect",
    "mode",
    "usePagination",
    "isTreeTable"
  ],
  columns: ["properties-styleClass"],
  dialogs: [
    "fullSize",
    "beginButtonText",
    "endButtonText",
    "hideBeginButton",
    "disableEsc"
  ],
  buttons: [
    "properties-type",
    //* button style type
    "properties-icon",
    "onPress",
    "isMain"
  ],
  links: ["url"],
  dimensions: [
    "formatter",
    "interval"
  ],
  measures: [
    "formatter",
    "label",
    "color",
    "hideDataLabel",
    "opacity",
    "stackId"
  ],
  nodeeditors: ["onSelect", "onNodeDoubleClick", "enabled"],
  codeeditors: ["showCustomOption"]
};
const optsPerComps = {
  Input: [
    "properties-valueHelpOnly",
    "properties-editable",
    "properties-textAlign",
    "onChange",
    "onSubmit",
    "valueHelpV2-dialogId",
    "valueHelpV2-onConfirm",
    "valueHelpV2-onRequest"
  ],
  TextArea: [
    "properties-editable",
    "properties-rows",
    "properties-growing",
    "proerties-growingMaxLines",
    "properties-height",
    "onChange"
  ],
  RichTextEditor: ["properties-editable", "properties-height"],
  Button: [
    "properties-type",
    //* button style type
    "properties-text",
    "properties-icon",
    "onPress",
    "isMain"
  ],
  Select: ["list", "onChange"],
  CheckBox: ["text", "onChange"],
  ComboBox: "Select",
  Date: ["format", "displayFormat", "onChange"],
  DateRange: "Date",
  Radio: "Select",
  Number: ["unit"],
  Quantity: "Number",
  Amount: "Number",
  ScannerButton: ["onScan", "onError"],
  Link: ["onPress", "text"],
  Uploader: ["onChange", "properties-buttonOnly"],
  Status: ["state", "inverted"],
  VBox: [
    "style",
    "justifyContent",
    "alignItems",
    "wrap",
    "displayInline",
    "fitContainer",
    "gap",
    "padding"
  ],
  HBox: "VBox",
  Panel: ["onToggle"],
  BarChart: [
    "direction",
    "noLegend",
    "chartConfig",
    "tooltipConfig",
    "syncId"
  ],
  DonutChart: [
    "centerLabel",
    "noLegend",
    "chartConfig",
    "tooltipConfig",
    "syncId"
  ],
  LineChart: [
    "noLegend",
    "chartConfig",
    "tooltipConfig",
    "syncId"
  ],
  RadarChart: [
    "noLegend",
    "chartConfig",
    "tooltipConfig",
    "syncId"
  ],
  CardHeader: [
    "properties-subtitleText",
    "properties-avatar",
    "properties-description",
    "properties-status"
  ],
  AnalyticalCardHeader: [
    "properties-subtitleText",
    "properties-trend",
    "properties-scale",
    "properties-state",
    "properties-description",
    "properties-status",
    "unit",
    "value"
  ],
  NumericSideIndicator: ["title", "properties-state", "unit"],
  Wizard: ["selectedStep", "onStepChange"],
  WizardStep: ["properties-icon"],
  Grid: [
    "style",
    "defaultSpan",
    "defaultIndent",
    "vSpacing",
    "hSpacing",
    "position",
    "padding"
  ],
  Split: ["style", "vertical", "resizable", "size", "minSize", "padding"]
};
const getMixedOptions = (appliedOptions, { type, compType }) => {
  const availableOpts4Comp = [];
  if (compType) {
    const optsPer = optsPerComps[compType];
    if ((0, import_lib_core.isString)(optsPer)) {
      (optsPerComps[optsPer] || []).forEach((each) => {
        availableOpts4Comp.push(each);
      });
    } else {
      (optsPer || []).forEach((each) => {
        availableOpts4Comp.push(each);
      });
    }
  }
  const availableOpts4Type = [];
  if (type) {
    const optsPer = optsPerTypes[type];
    if ((0, import_lib_core.isString)(optsPer)) {
      (optsPerTypes[optsPer] || []).forEach((each) => {
        availableOpts4Type.push(each);
      });
    } else {
      (optsPer || []).forEach((each) => {
        availableOpts4Type.push(each);
      });
    }
  }
  const mixedOptions = commonOptions.concat(availableOpts4Comp).concat(availableOpts4Type);
  const manualOptions = appliedOptions.reduce((acc, each) => {
    if (!mixedOptions.includes(each.key)) {
      acc = acc.concat(each.key);
    }
    return acc;
  }, []);
  return mixedOptions.concat(manualOptions);
};
const getOptionsWithCombinedKey = (obj, propertyName) => {
  if (obj[propertyName]) {
    return Object.entries(obj[propertyName]).map(([propKey, propValue]) => {
      return __spreadValues({
        key: `${propertyName}-${propKey}`
      }, propValue);
    });
  }
  return [];
};
const getOptionsOnDialog = (dataOnDialog) => {
  if (dataOnDialog === void 0) {
    return;
  }
  const currentDialogOptions = Object.entries(dataOnDialog).filter(([, value]) => {
    if ((0, import_lib_core.isObject)(value)) {
      return Object.hasOwn(value, "type") && Object.hasOwn(value, "value") || Object.hasOwn(value, "type") && Object.hasOwn(value, "seq");
    }
    return false;
  }).map(([key, value]) => {
    return __spreadValues({ key }, value);
  });
  currentDialogOptions.push(
    ...getOptionsWithCombinedKey(dataOnDialog, "properties")
  );
  currentDialogOptions.push(
    ...getOptionsWithCombinedKey(dataOnDialog, "valueHelpV2")
  );
  currentDialogOptions.sort((0, import_lib_core.createSorter)(["seq"]));
  return currentDialogOptions;
};
const metaKeyStucture = {
  forms: {
    containers: {
      fields: {
        components: {}
      }
    }
  },
  tables: {
    columns: {
      components: {}
    },
    toolbars: {
      buttons: {
        buttons: {}
      }
    }
  },
  sections: {
    components: {},
    sections: {}
  },
  wizards: {
    steps: {
      sections: {}
    }
  },
  cards: {
    sections: {}
  },
  charts: {
    dimensions: {},
    measures: {}
  },
  headers: {
    buttons: {
      buttons: {}
    },
    links: {}
  },
  codeeditors: {
    components: {}
  },
  nodeeditors: {
    components: {}
  },
  dialogs: {
    forms: {},
    tables: {},
    codeeditors: {
      components: {}
    },
    nodeeditors: {
      components: {}
    },
    sections: {
      components: {},
      sections: {}
    }
  }
};
const getOtherContentKeys = (parentObj, myKey, myLayoutKey) => {
  return Object.entries(parentObj).reduce((acc, [metaKey, metaValue]) => {
    if ([
      "forms",
      "tables",
      "sections",
      "codeeditors",
      "nodeeditors",
      "components"
    ].includes(metaKey) && metaValue.length > 0) {
      metaValue.forEach((each) => {
        if (myLayoutKey === metaKey && each.key === myKey) {
          return;
        }
        acc.push(`[${metaKey}] ${each.key}`);
      });
    }
    return acc;
  }, []);
};
const getSubKeys = ([parentKey, parentStructure, parentObj]) => {
  const parentEntries = Object.entries(parentStructure[parentKey]);
  if (parentEntries.length === 0) {
    if (metaKeyStucture[parentKey] === void 0) {
      return [];
    }
    if (parentObj.type) {
      if (["Form", "Table", "Wizard", "Card"].includes(parentObj.type) || parentObj.type.endsWith("Chart")) {
        const newParentKey = parentObj.type.endsWith("Chart") ? "charts" : parentObj.type.toLowerCase() + "s";
        return Object.entries(metaKeyStucture[newParentKey]).map(([key]) => [
          key,
          metaKeyStucture[newParentKey]
        ]);
      } else if (parentObj.type === "CardHeader") {
        return [];
      }
    }
    return Object.entries(metaKeyStucture[parentKey]).map(([key]) => [
      key,
      metaKeyStucture[parentKey]
    ]);
  } else {
    return Object.entries(parentStructure[parentKey]).map(([key]) => [
      key,
      parentStructure[parentKey]
    ]);
  }
};
const addItemsBySubObj = (builder, [key_s, parentStructure], { rootIdx, rootKey_s, parentObj, parentItem, depth }) => {
  const inDepth = depth || 0;
  const subKeyEntries = getSubKeys([key_s, parentStructure, parentObj]);
  if (subKeyEntries.length === 0) {
    return [];
  }
  const innerItems = [];
  subKeyEntries.forEach(([subKey_s, subStructure]) => {
    const subKey = subKey_s.replace(/s{1}$/, "");
    const itemKeys = [];
    if (!parentItem) {
      itemKeys.push({ key: rootKey_s, index: rootIdx });
      builder.addToolbarButton(`add${subKey}Btn_${rootIdx}`, {
        icon: "sap-icon://add",
        text: `${subKey} \uCD94\uAC00`,
        confirmMessage: "Are you sure to add?",
        properties: {
          enabled: key_s === "dialogs" && parentObj.metaId ? false : true
        },
        onPress: (fn) => fn.addExtraObject({
          keys: [...itemKeys, { key: subKey_s }],
          subKey
        })
      });
    } else {
      itemKeys.push(...parentItem.keys);
    }
    if (parentItem) {
      parentItem.sub2Key_list.add(subKey);
      parentItem.sub2Key_s_list.add(subKey_s);
    }
    if (parentObj[subKey_s] === void 0 || parentObj[subKey_s].length === 0) {
      return [];
    }
    parentObj[subKey_s].forEach((subObj, subIndex) => {
      let text;
      if (subKey === "field") {
        text = subObj.label;
      } else if (Object.hasOwn(subObj, "title") && !(0, import_lib_core.isObject)(subObj.title)) {
        text = subObj.title;
      } else if (Object.hasOwn(subObj, "text")) {
        if (Object.hasOwn(subObj.text, "value")) {
          text = subObj.text.value;
        } else {
          text = subObj.text;
        }
      } else {
        text = (0, import_lib_core.tryit)(() => subObj.properties.text.value, "");
      }
      if (!text) {
        if (Object.hasOwn(subObj, "value")) {
          if (subObj.value.type === "String") {
            text = subObj.value.value;
          }
        }
      }
      let type;
      if (subKey === "button") {
        type = "";
      } else {
        if (Object.hasOwn(subObj, "type")) {
          if (Object.hasOwn(subObj.type, "value")) {
            type = subObj.type.value;
          } else {
            type = subObj.type;
          }
        }
      }
      const item = __spreadProps(__spreadValues({}, parentItem), {
        keys: [...itemKeys, { key: subKey_s, index: subIndex }],
        subKey,
        subKey_s,
        sub2Key_list: /* @__PURE__ */ new Set(),
        sub2Key_s_list: /* @__PURE__ */ new Set(),
        command: Array(inDepth + 1).join("  ") + `Add ${subKey}`,
        key: subObj.key,
        ignore: subObj.ignore,
        bound: !!subObj.binding,
        type,
        props: "Edit",
        text
      });
      const innerItems2 = addItemsBySubObj(builder, [subKey_s, subStructure], {
        rootIdx,
        rootKey_s,
        parentObj: subObj,
        parentItem: item,
        depth: inDepth + 1
      });
      item.sub2Key_list = Array.from(item.sub2Key_list);
      item.sub2Key_s_list = Array.from(item.sub2Key_s_list);
      item.sub2Key_list.forEach((sub2Key, sub2Idx) => {
        item[`sub2Key_${sub2Idx}`] = sub2Key;
        item[`sub2Key_s_${sub2Idx}`] = item.sub2Key_s_list[sub2Idx];
        item[`addSubBtn_${sub2Idx}`] = `Add ${sub2Key}`;
      });
      innerItems.push(item);
      innerItems.push(...innerItems2);
    });
  });
  return innerItems;
};
const addMetaTable = (builder, rootObj, rootIdx, rootKey_s) => {
  const rootKey = rootKey_s.replace(/s{1}$/, "");
  builder.addTable(`${rootKey}_${rootIdx}`, {
    icon: rootObj.ignore === true ? "sap-icon://cancel" : "",
    title: [rootKey, " "].join("").concat(rootObj.title || [" [", rootObj.key, "]"].join(""))
  }).addToolbarButton("edit", {
    icon: "sap-icon://edit",
    text: `${rootKey} \uD3B8\uC9D1`,
    onPress: (fn) => fn.openEditDialog2({
      rootKey_s,
      indexValue: rootIdx
    })
  });
  const listItems = addItemsBySubObj(builder, [rootKey_s, metaKeyStucture], {
    rootIdx,
    rootKey_s,
    parentObj: rootObj
  });
  if (["forms", "tables", "codeeditors"].includes(rootKey_s)) {
    builder.addToolbarAction("moveToDialog", {
      properties: {
        icon: "sap-icon://indent"
      },
      text: `${rootKey} - Dialog\uC73C\uB85C \uC774\uB3D9`,
      onPress: (fn) => fn.toggleMoveDialog(true, {
        rootKey_s,
        indexValue: rootIdx
      })
    });
    builder.addToolbarAction("exportMeta", {
      properties: {
        icon: "sap-icon://forward"
      },
      text: `${rootKey} - Export`,
      onPress: (fn) => fn.toggleExportDialog(true, {
        rootKey_s,
        indexValue: rootIdx
      })
    });
  }
  builder.addColumn("command", {
    width: "10rem",
    text: "Command"
  }).addColumn("key", {
    text: "Key"
  }).addColumn("ignore", {
    text: "ignore",
    width: "5rem",
    component: import_form_and_table.ft.CheckBox({
      properties: {
        enabled: false
      }
    })
  }).addColumn("bound", {
    text: "Bound",
    width: "5rem",
    component: import_form_and_table.ft.CheckBox({
      properties: {
        enabled: false
      }
    })
  }).addColumn("type", {
    width: "5rem",
    text: "Type"
  }).addColumn("text", {
    text: "Label/Text"
  }).addColumn("props", {
    text: "Properties",
    width: "5rem",
    component: import_form_and_table.ft.Button({
      properties: {
        icon: "sap-icon://edit",
        type: window.sap.m.ButtonType.Transparent
      },
      onPress: (fn) => fn.openSubDialog
    })
  });
  const countOfSub2Key = listItems.reduce((acc, cur) => {
    if (acc < cur.sub2Key_list.length) {
      return cur.sub2Key_list.length;
    }
    return acc;
  }, 0);
  (0, import_lib_core.repeat)({ maxTimes: countOfSub2Key }, ({ curIndex }) => {
    builder.addColumn(`addSubBtn_${curIndex}`, {
      text: "",
      component: import_form_and_table.ft.Button({
        confirmMessage: "Are you sure to add?",
        properties: {
          icon: "sap-icon://add",
          visible: ["{=", "!!${sub2Key_", curIndex, "}", "}"].join("")
        },
        onPress: (fn) => fn.addExtraObject({ sub2Index: curIndex })
      })
    });
  });
  builder.addItems(listItems);
  return builder;
};
const getSingular = (word) => word.replace(/s{1}$/, "");
const getTargetArray = (state, inKeys) => {
  let innerState = state;
  let firstIsLast = false;
  let lastKey;
  const { target } = state.extra.dialog;
  const keys = inKeys || target.keys;
  let parentObj;
  let parentKey;
  keys.forEach(({ key, index: keyIdx }, index) => {
    const isLast = keys.length - 1 === index;
    if (isLast) {
      parentObj = innerState;
      if (innerState[key] === void 0) {
        let defaultValue;
        if (["Form", "Table", "Wizard"].includes(innerState.type)) {
          const newParentKey = innerState.type.toLowerCase();
          defaultValue = constants[newParentKey][key];
        } else if (innerState.type.endsWith("Chart")) {
          const newParentKey = "chart";
          defaultValue = constants[newParentKey][key];
        } else {
          defaultValue = constants[getSingular(parentKey)][key];
        }
        if (!defaultValue) {
          throw new Error(
            "\uD574\uB2F9 key\uC758 \uAC12 \uC874\uC7AC\uD558\uC9C0 \uC54A\uC74C([" + parentKey + "][" + key + "])"
          );
        }
        innerState[key] = defaultValue;
        innerState = innerState[key];
      } else {
        innerState = innerState[key];
      }
      lastKey = key;
      if (index === 0) {
        firstIsLast = true;
      } else {
      }
    } else {
      parentKey = key;
      innerState = innerState[key][keyIdx];
    }
  });
  return { parentObj, metaArray: innerState, firstIsLast, lastKey };
};
const getTargetObj = (state, inKeys) => {
  const { target } = state.extra.dialog;
  const keys = inKeys || target.keys;
  const result = getTargetArray(state, keys);
  if (target.keys.length === 0) {
    return result;
  } else {
    const lastIndex = target.keys[target.keys.length - 1].index;
    return __spreadProps(__spreadValues({}, result), {
      metaObj: result.metaArray[lastIndex],
      lastIndex
    });
  }
};
const getParentObjects = (meta, keys) => {
  const inKeys = (0, import_lib_core.clone)(keys);
  inKeys.pop();
  const parentKey = inKeys[inKeys.length - 1];
  const arr1 = inKeys.reduce(
    (acc, { key }) => {
      const newAcc = [];
      acc.forEach((eachAcc) => {
        if (eachAcc[key] !== void 0) {
          newAcc.push(...eachAcc[key]);
        }
      });
      return newAcc;
    },
    [meta]
  );
  inKeys.unshift("dialogs");
  const arr2 = inKeys.reduce(
    (acc, key) => {
      const newAcc = [];
      acc.forEach((eachAcc) => {
        if (eachAcc[key] !== void 0) {
          newAcc.push(...eachAcc[key]);
        }
      });
      return newAcc;
    },
    [meta]
  );
  return {
    list: arr1.concat(arr2),
    parentType: parentKey.key.replace(/s{1}$/, "")
  };
};
const replaceInvalidChar = (key) => {
  return key.replace(/[^-A-Za-z0-9_.:]/g, "_").replace(/^[^A-Za-z_]{1}/, "_");
};
const getBindParams = (value) => {
  if (!(0, import_lib_core.isString)(value)) {
    return [];
  }
  const regex = /\{{2}([^{}]{1,})\}{2}/g;
  const found = value.match(regex);
  return found || [];
};
const getOptionTarget = (key, currentDialog, options = {}) => {
  const { replaceWith, deleteTarget } = options;
  let oldValue;
  if (replaceWith !== void 0) {
    oldValue = getOptionTarget(replaceWith, currentDialog, {
      deleteTarget: true
    });
  }
  if ((0, import_mods.splitSpecialKeyIfExists)(key)) {
    const [propsKey, propsName] = (0, import_mods.splitSpecialKeyIfExists)(key);
    if (currentDialog[propsKey] === void 0) {
      currentDialog[propsKey] = {};
    }
    if (currentDialog[propsKey][propsName] === void 0) {
      currentDialog[propsKey][propsName] = {};
    }
    if (replaceWith !== void 0) {
      currentDialog[propsKey][propsName] = oldValue;
    }
    if (deleteTarget === true) {
      const clonedTarget = (0, import_lib_core.clone)(currentDialog[propsKey][propsName]);
      delete currentDialog[propsKey][propsName];
      return clonedTarget;
    }
    return currentDialog[propsKey][propsName];
  } else {
    if (currentDialog[key] === void 0) {
      currentDialog[key] = {};
    }
    if (replaceWith !== void 0) {
      currentDialog[key] = oldValue;
    }
    if (deleteTarget === true) {
      const clonedTarget = (0, import_lib_core.clone)(currentDialog[key]);
      delete currentDialog[key];
      return clonedTarget;
    }
    return currentDialog[key];
  }
};
const getCombinedKeyString = (keys, meta, options = {}) => {
  const { combineAllKeys, prefix = "" } = options;
  return keys.reduce(
    (acc, { key, index }) => {
      if (index === void 0) {
        return acc;
      }
      acc.innerState = acc.innerState[key][index];
      if (combineAllKeys !== true && ["containers", "fields", "dialogs", "columns"].includes(key)) {
      } else {
        if (key === "sections" && ["Form", "Table"].includes(acc.innerState.type)) {
          if (prefix.endsWith("sections.")) {
            acc.result = [];
          }
          const newKey = acc.innerState.type.toLowerCase() + "s";
          acc.result.push(newKey);
        }
        acc.result.push(acc.innerState.key);
      }
      return acc;
    },
    { result: [], innerState: meta }
  ).result.map((key) => replaceInvalidChar(key)).join(".");
};
const getFunctionHeader = (currentDialog, options = {}) => {
  const { addBracket, addContextKeys } = options;
  const strParams = addContextKeys ? currentDialog.target.contextKeys.join(", ") : "";
  if (strParams === "") {
    return "";
  }
  return [
    (currentDialog.target.isAsync ? "async " : "").concat("("),
    addBracket ? "{" : "",
    strParams,
    addBracket ? "}" : "",
    ")"
  ].filter(Boolean).join("");
};
const getDefaultPathValue = (optionKey, keys, meta) => {
  if (optionKey === "value") {
    const prefix = `state.${keys.filter(({ key }) => key !== "dialogs")[0].key}.`;
    const keyString = getCombinedKeyString(keys, meta, { prefix });
    if (prefix.endsWith("sections.")) {
      if (keyString.startsWith("forms.") || keyString.startsWith("tables.")) {
        return `state.${keyString}`;
      }
    }
    return `${prefix}${keyString}`;
  }
  if (optionKey === "list") {
    return "state.hints.";
  }
  if (optionKey === "codeeditor") {
    return "state.codeeditors";
  }
  if (optionKey === "nodeeditor") {
    return "state.nodeeditors";
  }
  if (optionKey === "section") {
    return "state.sections";
  }
  return void 0;
};
const extractFunctions = (target, values) => {
  const keys = Object.keys(target);
  keys.forEach((key) => {
    if ((0, import_lib_core.isArray)(target[key])) {
      target[key].forEach((each) => {
        extractFunctions(each, values);
      });
    } else if ((0, import_lib_core.isObject)(target[key]) && key === "properties") {
      extractFunctions(target[key], values);
    } else if ((0, import_lib_core.isObject)(target[key]) && target[key].hasOwnProperty("type")) {
      if (target[key].type === "Function") {
        values.push(target[key].value);
      }
    }
  });
};
const ComponentsList = [
  "Button",
  "Input",
  "Link",
  "Text",
  "Uploader",
  "DateRange",
  "Number",
  "Select",
  "Radio",
  "ComboBox",
  "Quantity",
  "Amount",
  "CheckBox",
  "Status",
  "TextArea",
  "RichTextEditor",
  "Date",
  "CodeEditor",
  "ScannerButton",
  "Image",
  "Title"
].sort();
const SectionsList = [
  "VBox",
  "HBox",
  "Grid",
  "Split",
  "Form",
  "Table",
  "Panel",
  "Wizard",
  "Card",
  "CardHeader",
  "AnalyticalCardHeader",
  "BarChart",
  "LineChart",
  "DonutChart",
  "RadarChart"
].sort();
const TypeList = [
  "String",
  "Number",
  "Boolean",
  "Function",
  "Path",
  "Mode",
  "Array"
].sort();
