var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var slice_exports = {};
__export(slice_exports, {
  builderActions: () => builderActions,
  default: () => slice_default,
  initialState: () => initialState,
  metaAttributes: () => metaAttributes
});
module.exports = __toCommonJS(slice_exports);
var import_toolkit = require("@reduxjs/toolkit");
var import_lib_core = require("@bsgp/lib-core");
var import_modules = require("./modules");
var import_forms = require("./forms");
var import_mods = require("../mods");
const initialMeta = {
  id: "",
  description: "",
  wrapForms: true,
  renderAsReact: false,
  paths: [],
  forms: [],
  tables: [],
  dialogs: [],
  codeeditors: [],
  nodeeditors: [],
  sections: [],
  headers: [],
  functions: {
    initialization: { type: "js", content: "" },
    global: { type: "js", content: "" }
  }
};
const metaAttributes = Object.keys(initialMeta);
const metaSlice = (0, import_toolkit.createSlice)({
  name: "metaSlice",
  initialState: initialMeta,
  reducers: {}
});
const initialState = __spreadProps(__spreadValues({}, initialMeta), {
  extra: {
    selectedPath: "",
    errorMessage: "",
    metaList: [],
    message: "",
    version: {
      description: ""
    },
    move: {
      dialogKey: ""
    },
    export: {
      globalFunction: false,
      remain: false
    },
    deploy: {
      partner: "",
      system: "",
      partners: [],
      systems: [],
      version: "",
      description: "",
      presys: []
    },
    dialog: {
      isOpen: false,
      isCodeEditorOpen: false,
      isDeployOpen: false,
      isCreateVersionOpen: false,
      isMoveOpen: false,
      invalid: {},
      target: {
        keys: [],
        functionUid: "",
        type: ""
      }
    }
  }
});
function migrateFormsTables(meta) {
  if (Object.hasOwn(meta, "tables")) {
    meta.tables.forEach((table) => {
      if (table.tableItems) {
        table.items = table.tableItems;
        delete table.tableItems;
      }
    });
  }
  if (Object.hasOwn(meta, "codeeditors")) {
    meta.codeeditors.forEach((codeeditor) => {
      if (codeeditor.buttons) {
        codeeditor.components = codeeditor.buttons;
        delete codeeditor.buttons;
      }
    });
  }
  if (Object.hasOwn(meta, "nodeeditors")) {
    meta.nodeeditors.forEach((nodeeditor) => {
      if (nodeeditor.buttons) {
        nodeeditor.components = nodeeditor.buttons;
        delete nodeeditor.buttons;
      }
    });
  }
  if (Object.hasOwn(meta, "sections")) {
    meta.sections.forEach((section) => {
      if (section.subsections) {
        section.sections = section.subsections;
        delete section.subsections;
        if (section.sections.length > 0) {
          section.sections.forEach((subsection) => {
            if (subsection.subsubsections) {
              subsection.sections = subsection.subsubsections;
              delete subsection.subsubsections;
            }
          });
        }
      }
    });
  }
  if (Object.hasOwn(meta, "forms")) {
    meta.forms.forEach((form, fIdx) => {
      if (form.containers.length > 0) {
        form.containers.forEach((container, ctIdx) => {
          if ((0, import_lib_core.isObject)(container.ignore)) {
            container.ignore = false;
          }
        });
      }
    });
  }
}
function convertToNewStructure(meta) {
  const clonedMeta = (0, import_lib_core.clone)(meta);
  if (Object.hasOwn(clonedMeta, "functions")) {
    if (clonedMeta.functions.global === void 0) {
      clonedMeta.functions.global = {
        type: "js",
        content: builderSlice.getInitialState().functions.global.content
      };
    }
  }
  if (Object.hasOwn(clonedMeta, "headers")) {
    if (clonedMeta.headers.length > 0 && clonedMeta.headers[0].links === void 0) {
      clonedMeta.headers[0].links = [];
    }
  }
  migrateFormsTables(clonedMeta);
  if (Object.hasOwn(clonedMeta, "dialogs")) {
    clonedMeta.dialogs.forEach((dialog) => {
      migrateFormsTables(dialog);
    });
  }
  return clonedMeta;
}
function recoverMeta(state) {
  if (state.extra.backup !== void 0) {
    metaAttributes.forEach((attr) => {
      state[attr] = state.extra.backup[attr];
    });
    delete state.extra.backup;
  }
}
function moveElementByIndex(items, firstIndex, secondIndex) {
  const results = items.slice();
  const removedElement = results.splice(firstIndex, 1)[0];
  results.splice(secondIndex, 0, removedElement);
  return results;
}
function findComponents(metaObj, dialogTarget) {
  const components = [];
  if (metaObj.columns) {
    metaObj.columns.forEach((col, coIdx) => {
      if (col.components) {
        col.components.forEach((comp, compIdx) => {
          const newTarget = (0, import_lib_core.clone)(dialogTarget);
          newTarget.keys.push({ key: "columns", index: coIdx });
          newTarget.keys.push({
            key: "components",
            index: compIdx
          });
          components.push({ comp, target: newTarget });
        });
      }
    });
  } else if (metaObj.containers) {
    metaObj.containers.forEach((container, cIdx) => {
      if (container.fields) {
        container.fields.forEach((field, fiIdx) => {
          if (field.components) {
            field.components.forEach((comp, compIdx) => {
              const newTarget = (0, import_lib_core.clone)(dialogTarget);
              newTarget.keys.push({
                key: "containers",
                index: cIdx
              });
              newTarget.keys.push({
                key: "fields",
                index: fiIdx
              });
              newTarget.keys.push({
                key: "components",
                index: compIdx
              });
              components.push({ comp, target: newTarget });
            });
          }
        });
      }
    });
  }
  if (metaObj.sections) {
    metaObj.sections.forEach((section, sIdx) => {
      if (section.sections || section.components || section.steps) {
        const newTarget = (0, import_lib_core.clone)(dialogTarget);
        newTarget.keys.push({
          key: "sections",
          index: sIdx
        });
        components.push(...findComponents(section, newTarget));
      }
    });
  }
  if (metaObj.components) {
    metaObj.components.forEach((comp, compIdx) => {
      const newTarget = (0, import_lib_core.clone)(dialogTarget);
      newTarget.keys.push({
        key: "components",
        index: compIdx
      });
      components.push({ comp, target: newTarget });
    });
  }
  if (metaObj.steps) {
    metaObj.steps.forEach((step, stepIdx) => {
      const newTarget = (0, import_lib_core.clone)(dialogTarget);
      newTarget.keys.push({
        key: "steps",
        index: stepIdx
      });
      components.push(...findComponents(step, newTarget));
    });
  }
  return components;
}
const builderSlice = (0, import_toolkit.createSlice)({
  name: "builderSlice",
  initialState,
  reducers: {
    _updateMetaProperty: (state, action) => {
      const { key, value, extra } = action.payload;
      if (extra === true) {
        state.extra[key] = value;
        if (key === "errorMessage") {
          state.extra.message = "";
        } else if (key === "message") {
          state.extra.errorMessage = "";
        }
      } else {
        state[key] = value;
      }
    },
    _updateRequiredKey: (state, action) => {
      var _a;
      const { requiredKey, value } = action.payload;
      const { metaObj, lastKey } = (0, import_modules.getTargetObj)(state);
      const { dialog } = state.extra;
      metaObj[requiredKey] = value;
      if (requiredKey === "key") {
        if ([
          "tables",
          "forms",
          "sections",
          "steps"
        ].includes(lastKey)) {
          findComponents(metaObj, dialog.target).forEach(({ comp, target }) => {
            var _a2;
            if (((_a2 = comp.value) == null ? void 0 : _a2.type) === "Path") {
              comp.value.value = (0, import_modules.getDefaultPathValue)(
                "value",
                target.keys,
                state,
                {
                  target
                }
              );
            }
          });
        } else if (((_a = metaObj.value) == null ? void 0 : _a.type) === "Path") {
          metaObj.value.value = (0, import_modules.getDefaultPathValue)(
            "value",
            dialog.target.keys,
            state,
            {
              target: dialog.target
            }
          );
        }
      } else if (requiredKey === "metaId") {
        if (dialog.target.type === "dialogs") {
          Object.keys(import_modules.metaKeyStucture[dialog.target.type]).forEach((key) => {
            metaObj[key] = (0, import_lib_core.clone)(import_forms.dialog[key]);
          });
        }
      }
    },
    _moveToDialog: (state, action) => {
      const { dialogKey } = action.payload;
      const { target } = state.extra.dialog;
      const { rootKey_s, indexValue } = target;
      const targetObj = state[rootKey_s][indexValue];
      const dialog = state.dialogs.find((dialog2) => dialog2.key === dialogKey);
      dialog[rootKey_s].push(targetObj);
      state[rootKey_s].splice(indexValue, 1);
    },
    _moveFromDialog: (state, action) => {
      const { index } = action.payload;
      const { lastKey, lastIndex, metaObj, metaArray } = (0, import_modules.getTargetObj)(state);
      const numIndex = Number(index);
      if (!isNaN(numIndex) && state[lastKey].length > index) {
        state[lastKey].splice(index, 0, metaObj);
      } else {
        state[lastKey].push(metaObj);
      }
      metaArray.splice(lastIndex, 1);
    },
    _moveObject: (state, action) => {
      const { newIndex } = action.payload;
      const {
        parentObj: parentObject,
        lastKey: parentArrayKey,
        lastIndex: currentIndex
      } = (0, import_modules.getTargetObj)(state);
      const newParentArray = moveElementByIndex(
        parentObject[parentArrayKey],
        currentIndex,
        newIndex
      );
      parentObject[parentArrayKey] = newParentArray;
    },
    _moveObjectToAnotherParent: (state, action) => {
      const { newParentKey } = action.payload;
      const { list: parentObjects } = (0, import_modules.getParentObjects)(
        state,
        state.extra.dialog.target.keys
      );
      const { lastIndex, metaObj, metaArray } = (0, import_modules.getTargetObj)(state);
      const targetParentObject = parentObjects.find(
        (each) => each.key === newParentKey
      ) || metaArray.find((each) => each.key === newParentKey);
      targetParentObject[state.extra.dialog.target.type].push(metaObj);
      metaArray.splice(lastIndex, 1);
    },
    _openCodeEditorDialog: (state, action) => {
      const { uid, isAsync, contextKeys } = action.payload;
      state.extra.dialog.target.functionUid = uid;
      state.extra.dialog.target.isAsync = isAsync;
      state.extra.dialog.target.contextKeys = contextKeys;
      state.extra.dialog.isCodeEditorOpen = true;
      state.extra.backup = metaAttributes.reduce((acc, attr) => {
        acc[attr] = (0, import_lib_core.clone)(state[attr]);
        return acc;
      }, {});
      if (!state.functions[uid].content) {
        if (uid === "global") {
          state.functions[uid].content = "return {\n	testFunction: async () => {\n\n	}\n};";
        } else {
          const contents = isAsync ? "async () => {\n\n};" : "() => {\n\n};";
          state.functions[uid].content = (0, import_modules.getFunctionHeader)(state.extra.dialog, {
            addBracket: true
          }).concat(contents);
        }
      }
    },
    _addPath: (state) => {
      state.paths.push({});
    },
    _updateTableCell: (state, action) => {
      const { index, value, cellName, tabName } = action.payload;
      const row = state[tabName][index];
      if (row) {
        row[cellName] = value;
      }
    },
    _updateTableRow: (state, action) => {
      const { index, row, tabName } = action.payload;
      Object.keys(row).forEach((cellName) => {
        state[tabName][index][cellName] = row[cellName];
      });
    },
    _addExtraObject: (state, action) => {
      const { keys, obj } = action.payload;
      const newObj = (0, import_lib_core.clone)(obj);
      const { metaArray, firstIsLast, lastKey } = (0, import_modules.getTargetArray)(state, keys);
      if (firstIsLast === true) {
        const suffix = metaArray.length + 1;
        newObj.key = `${lastKey}${suffix}`;
        newObj.title = `${lastKey}(${suffix})`;
        if (lastKey === "codeeditors") {
          newObj.value = {
            value: [
              (0, import_modules.getDefaultPathValue)("codeeditor", keys, state),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
        if (lastKey === "nodeeditors") {
          newObj.value = {
            value: [
              (0, import_modules.getDefaultPathValue)("nodeeditor", keys, state),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
      } else {
        if (!newObj.key) {
          newObj.key = (0, import_lib_core.makeid)(5, "a");
        }
        if (lastKey === "components") {
          newObj.type = "Text";
          newObj.value = {
            value: [(0, import_modules.getDefaultPathValue)("value", keys, state), newObj.key].join(
              "."
            ),
            type: "Path",
            seq: 0
          };
        }
        if (lastKey === "codeeditors") {
          const suffix = metaArray.length + 1;
          newObj.title = `${lastKey}(${suffix})`;
          newObj.value = {
            value: [
              (0, import_modules.getDefaultPathValue)("codeeditor", keys, state),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
        if (lastKey === "nodeeditors") {
          const suffix = metaArray.length + 1;
          newObj.title = `${lastKey}(${suffix})`;
          newObj.value = {
            value: [
              (0, import_modules.getDefaultPathValue)("nodeeditor", keys, state),
              newObj.key
            ].join("."),
            type: "Path"
          };
        }
      }
      metaArray.push(newObj);
    },
    _updateInitFunc: (state, action) => {
      const { value } = action.payload;
      state.functions.initialization.content = value;
    },
    _openEditDialog2: (state, action) => {
      state.extra.dialog.isOpen = true;
      state.extra.dialog.target = __spreadValues({}, action.payload);
      state.extra.backup = metaAttributes.reduce((acc, attr) => {
        acc[attr] = (0, import_lib_core.clone)(state[attr]);
        return acc;
      }, {});
    },
    _addProperties: (state, action) => {
      const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(state);
      let dialogTablePropsPairs = [];
      let dialogTableVHV2Pairs = [];
      const dialogTableKeyPairs = Object.entries(currentDialog).filter(
        ([, value]) => {
          if ((0, import_lib_core.isObject)(value)) {
            return Object.hasOwn(value, "type") && Object.hasOwn(value, "value") || Object.hasOwn(value, "type") && Object.hasOwn(value, "seq");
          }
          return false;
        }
      );
      if ((0, import_lib_core.isTruthy)(currentDialog.properties)) {
        dialogTablePropsPairs = Object.entries(currentDialog.properties);
      }
      if ((0, import_lib_core.isTruthy)(currentDialog.valueHelpV2)) {
        dialogTableVHV2Pairs = Object.entries(currentDialog.valueHelpV2);
      }
      currentDialog[""] = { seq: 0, value: "", type: "String" };
      if ((0, import_lib_core.isTruthy)(dialogTableKeyPairs) || (0, import_lib_core.isTruthy)(dialogTablePropsPairs) || (0, import_lib_core.isTruthy)(dialogTableVHV2Pairs)) {
        const dialogOptions = dialogTableKeyPairs.concat(dialogTablePropsPairs).concat(dialogTableVHV2Pairs);
        const dialogOptionsSeqs = dialogOptions.map((each) => {
          return each[1].seq;
        });
        const maxSeq = dialogOptionsSeqs.reduce((acc, each) => {
          if (acc < each) {
            acc = each;
          }
          return acc;
        });
        currentDialog[""].seq = maxSeq + 1;
      }
    },
    _updateIgnoreOption: (state, action) => {
      const { value, key } = action.payload;
      const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(state);
      (0, import_modules.getOptionTarget)(key, currentDialog).ignore = value;
    },
    _updateKeyColumn: (state, action) => {
      const { oldKey, newKey } = action.payload;
      const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(state);
      (0, import_modules.getOptionTarget)(newKey, currentDialog, { replaceWith: oldKey });
    },
    _updateValueColumn: (state, action) => {
      const { nowKey, newValue } = action.payload;
      const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(state);
      (0, import_modules.getOptionTarget)(nowKey, currentDialog).value = newValue;
    },
    _updateValueType: (state, action) => {
      const { newType, row } = action.payload;
      let newValue = row.value;
      switch (newType) {
        case "Function": {
          newValue = (0, import_lib_core.makeid)(4);
          while (state.functions[newValue]) {
            newValue = (0, import_lib_core.makeid)(4);
          }
          state.functions[newValue] = {
            type: "js",
            content: ""
          };
          break;
        }
        case "Path": {
          const { dialog } = state.extra;
          newValue = (0, import_modules.getDefaultPathValue)(row.key, dialog.target.keys, state, {
            target: dialog.target
          });
          break;
        }
        default: {
          break;
        }
      }
      const { metaObj: currentDialog } = (0, import_modules.getTargetObj)(state);
      if ((0, import_mods.splitSpecialKeyIfExists)(row.key)) {
        const [propsKey, propsName] = (0, import_mods.splitSpecialKeyIfExists)(row.key);
        currentDialog[propsKey][propsName].value = newValue;
        currentDialog[propsKey][propsName].type = newType;
      } else {
        currentDialog[row.key].value = newValue;
        currentDialog[row.key].type = newType;
      }
    },
    // _deleteFromTableRow: (state, action) => {
    //   const { type, target } = action.payload;
    //   const { metaObj: currentDialog } = getTargetObj(state);
    // },
    _updateOptionsFunc: (state, action) => {
      const { newValue, resultText } = action.payload;
      const { functions, extra } = state;
      functions[extra.dialog.target.functionUid].content = newValue;
      extra.dialog.target.resultText = resultText;
    },
    _undoDialogUpdate: (state, action) => {
      recoverMeta(state);
    },
    _closeDialog: (state, action) => {
      state.extra.dialog.isOpen = false;
    },
    _toggleDeployDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isDeployOpen = isOpen;
    },
    _updateDeployValue: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.extra.deploy[key] = action.payload[key];
      });
    },
    _toggleCreateVersionDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isCreateVersionOpen = isOpen;
    },
    _updateVersionValue: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.extra.version[key] = action.payload[key];
      });
    },
    _toggleMoveDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isMoveOpen = isOpen;
      if (isOpen && action.payload.options) {
        state.extra.dialog.target.keys = [];
        Object.entries(action.payload.options).forEach(([key, value]) => {
          state.extra.dialog.target[key] = value;
        });
      }
    },
    _toggleExportDialog: (state, action) => {
      const { isOpen } = action.payload;
      state.extra.dialog.isExportOpen = isOpen;
      if (isOpen && action.payload.options) {
        state.extra.export = { globalFunction: false, remain: false };
        Object.entries(action.payload.options).forEach(([key, value]) => {
          state.extra.dialog.target[key] = value;
        });
      }
    },
    _exportMeta: (state, action) => {
      const { funcValues } = action.payload;
      const { remain } = state.extra.export;
      const { rootKey_s, indexValue } = state.extra.dialog.target;
      if (!remain) {
        funcValues.forEach((value) => {
          delete state.functions[value];
        });
        state[rootKey_s].splice(indexValue, 1);
      }
    },
    _updateMoveValue: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.extra.move[key] = action.payload[key];
      });
    },
    _updateExportValue: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.extra.export[key] = action.payload[key];
      });
    },
    _confirmCodeorUpdate: (state, action) => {
      state.extra.dialog.isCodeEditorOpen = false;
    },
    _cancelCodeEditorUpdate: (state, action) => {
      const { dialog } = state.extra;
      dialog.isCodeEditorOpen = false;
      recoverMeta(state);
    },
    _getMetaData: (state, action) => {
      const initialState2 = metaSlice.getInitialState();
      const convertedMeta = convertToNewStructure(action.payload);
      Object.keys(convertedMeta).forEach((key) => {
        var _a;
        if (convertedMeta[key] !== void 0) {
          state[key] = convertedMeta[key];
        } else {
          state[key] = initialState2[key];
        }
        if (state.paths.length > 0) {
          state.paths = state.paths.map((each) => __spreadProps(__spreadValues({}, each), {
            origin: (0, import_lib_core.defined)(each.origin, each.value)
          }));
        }
        if (state.paths.length > 0) {
          state.extra.selectedPath = state.paths[0].origin;
        } else {
          const { selectedPath } = builderSlice.getInitialState().extra;
          state.extra.selectedPath = selectedPath;
        }
        state.title = (_a = state.paths.find(
          (each) => each.origin === state.extra.selectedPath
        )) == null ? void 0 : _a.title;
      });
    },
    _initAll: () => {
      return builderSlice.getInitialState();
    },
    _updateDialogInvalid: (state, action) => {
      const { requiredKey, value } = action.payload;
      const { dialog } = state.extra;
      if (requiredKey === "key") {
        const params = (0, import_modules.getBindParams)(value);
        const valueWithoutParams = params.reduce((acc, each) => {
          return acc.replace(each, "");
        }, value);
        const newValue = (0, import_modules.replaceInvalidChar)(valueWithoutParams);
        if (newValue !== valueWithoutParams) {
          dialog.invalid = __spreadProps(__spreadValues({}, dialog == null ? void 0 : dialog.invalid), {
            key: true
          });
        } else {
          dialog.invalid = __spreadProps(__spreadValues({}, dialog == null ? void 0 : dialog.invalid), {
            key: false
          });
        }
      }
    }
  }
});
const builderActions = builderSlice.actions;
var slice_default = builderSlice;
