import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import Config from "@bsgp/lib-config";
import { tryit } from "@bsgp/lib-core";
import { getPathsForCurrentUser } from "actions/adir";
import { formTable, ft } from "@bsgp/form-and-table";
import { FormTable as FormTableReact } from "@bsgp/ft-react";

function MainPage(props) {
  const { dispatch, currentUser, history } = props;
  const systemLogo = currentUser.system ? currentUser.system.logo : "";
  const systemName = currentUser.system ? currentUser.system.name : "";

  useEffect(() => {
    if (!currentUser.paths) {
      dispatch(getPathsForCurrentUser(currentUser));
    }

    const defaultPath = tryit(
      () => Config.ref.merged[Config.id.UI].DefaultEntry.Path
    );
    // console.log("location:", location);
    // if(location)
    defaultPath && history.replace(defaultPath);
  }, []);

  const builder = formTable({ vCenter: true, hCenter: true });
  builder.addSection("main", {
    type: "VBox",
    style: {
      marginTop: "35vh"
    },
    properties: {
      // fitContainer: true,
      // justifyContent: "Center"
    },
    alignItems: "Center",
    components: [
      ft.Image("logo", {
        properties: {
          src: systemLogo,
          style: {
            // width: "fit-content"
          }
        }
      })
    ]
  });
  return (
    <FormTableReact
      {...props}
      fn={{}}
      title={systemName}
      data={builder.done()}
    ></FormTableReact>
  );
}

// const onPressTile = (state, props) => oEvent => {
//   const { history } = props;

//   const customData_urlPath = oEvent
//     .getSource()
//     .getCustomData()
//     .reduce((result, cd) => {
//       if (cd.getKey() === "urlPath") {
//         result = cd.getValue();
//       }
//       return result;
//     }, null);

//   if (customData_urlPath) {
//     console.log(customData_urlPath);
//     history.push(customData_urlPath);
//   }
// };

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser
  };
}

export default compose(
  connect(mapStateToProps),
  withRouter
)(MainPage);
