var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React, { useState, useEffect } from "react";
import { Wizard, WizardStep } from "@ui5/webcomponents-react";
import { useRefineProperties } from "@/hooks/refineProps";
import SectionBuilder from "@/builders/section/Builder";
const WizardBuilder = (props) => {
  const refinedProperties = useRefineProperties(props);
  const [selectedStep, setSelectedStep] = useState(props.steps[0].name);
  useEffect(() => {
    if (props.selectedStep) {
      setSelectedStep(props.selectedStep);
    }
  }, [props.selectedStep]);
  return /* @__PURE__ */ React.createElement(
    Wizard,
    __spreadProps(__spreadValues({}, refinedProperties), {
      onStepChange: (event) => {
        setSelectedStep(event.detail.step.dataset.step);
        refinedProperties.onStepChange(event);
      }
    }),
    props.steps.map((eachStep) => {
      return /* @__PURE__ */ React.createElement(
        WizardStep,
        {
          key: eachStep.name,
          selected: selectedStep === eachStep.name,
          disabled: selectedStep !== eachStep.name,
          "data-step": eachStep.name,
          titleText: eachStep.title,
          icon: eachStep.properties.icon
        },
        eachStep.sections.map((secInStep) => /* @__PURE__ */ React.createElement(
          SectionBuilder,
          __spreadProps(__spreadValues({}, secInStep), {
            key: secInStep.name
          })
        ))
      );
    })
  );
};
export default WizardBuilder;
