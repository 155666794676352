var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
var controller_exports = {};
__export(controller_exports, {
  getMetaFuncContent: () => getMetaFuncContent,
  onCancel: () => onCancel,
  onClose: () => onClose,
  selectFormId: () => selectFormId
});
module.exports = __toCommonJS(controller_exports);
var import_form_and_table = require("@bsgp/form-and-table");
var import_lib_core = require("@bsgp/lib-core");
var import_slice = require("./slice");
var import_modules = require("./modules");
const getMetaFuncContent = ({ getProps, returnContextKeys }) => ({
  formKey,
  tableKey,
  fieldKey,
  functions,
  componentKey,
  type,
  functionUid,
  conv,
  autoClose = false
}) => (oEvent) => __async(void 0, null, function* () {
  const { _dispatch, _state } = getProps();
  const context = {
    componentKey
  };
  const newType = type.includes("-") ? type.split("-")[1] : type;
  switch (newType) {
    case "onPress":
    case "onChange":
    case "onSelect":
      {
        const { value, row, index } = import_form_and_table.functions.getValue(oEvent);
        context.value = value;
        context.row = row;
        context.index = index;
        context.items = oEvent.items;
        context.indices = oEvent.indices;
      }
      break;
    case "onRequest": {
      if (oEvent.renderAsReact) {
        const { value, row, index } = import_form_and_table.functions.getValue(oEvent);
        context.value = value;
        context.row = row;
        context.index = index;
      } else {
        context.index = oEvent.index;
        context.value = oEvent.value;
        context.row = oEvent.row;
      }
      break;
    }
    case "onConfirm": {
      context.items = oEvent.items;
      break;
    }
    case "onScan": {
      context.value = oEvent;
      break;
    }
    case "onSubmit": {
      const { value } = import_form_and_table.functions.getValue(oEvent);
      context.value = value;
      break;
    }
    case "onSort": {
      context.sorter = oEvent.sorter;
      break;
    }
    default: {
      break;
    }
  }
  if (returnContextKeys === true) {
    return Object.keys(context);
  }
  switch (type) {
    case "onChange":
      {
        const { value, index } = context;
        const { componentCtg } = oEvent;
        if (index === void 0) {
          if (!formKey) {
            _dispatch(
              import_slice.rendererActions._updateComponentData({
                componentCtg,
                componentKey,
                value: context.value
              })
            );
          } else {
            _dispatch(
              import_slice.rendererActions._updateFormData({
                formKey,
                componentKey,
                value: context.value
              })
            );
          }
        } else {
          _dispatch(
            import_slice.rendererActions._updateTableData({
              key: tableKey,
              value,
              index,
              colName: componentKey
            })
          );
        }
      }
      break;
    default: {
      break;
    }
  }
  try {
    return yield (0, import_modules.executeFunction)(getProps(), {
      functionIdentifier: [
        // `dialog(${dialogKey})`,
        `form(${formKey})`,
        `component(${componentKey})`,
        `property(${type})`
      ].join(":"),
      functions: functions || _state.meta.functions,
      uid: functionUid,
      moreContext: context,
      isAsync: true,
      oEvent
    });
  } finally {
    if (autoClose) {
      const oDialog = import_form_and_table.functions.getParentDialog(oEvent.oTable);
      if (oDialog) {
        oDialog.close();
      }
    }
  }
});
const selectFormId = ({ getProps }) => () => (oEvent) => {
  const { _dispatch } = getProps();
  const { formKey } = oEvent;
  _dispatch(import_slice.rendererActions._selectFormId({ formKey }));
};
const onClose = ({ getProps }) => (dialogKey) => () => {
  const { _dispatch, _state } = getProps();
  const { data } = _state;
  const isValueHelp = (0, import_lib_core.isFalsy)(data.dialogs[dialogKey]);
  if (!isValueHelp) {
    _dispatch(
      import_slice.rendererActions._updateDialogData({
        dialogKey,
        key: "isOpen",
        value: false
      })
    );
  }
};
const onCancel = ({ getProps }) => (dialogKey) => () => {
  const { _dispatch } = getProps();
  _dispatch(import_slice.rendererActions._restoreState(dialogKey));
};
