import React, { useEffect, useLayoutEffect } from "react";
import "App.css";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { tryit } from "@bsgp/lib-core";
import QsDs from "@bsgp/quicksight-dashboard";

import Auth from "components/auth";
import Privacy from "components/Privacy";
import TestPages from "test-pages";
import { initUI5 } from "App.ui5";
import MessageIndicator from "MessageIndicator";
import MenuButton from "MenuButton";
import HomeButton from "HomeButton";
import SettingsButton from "SettingsButton";
import Languages from "Languages";
import { titleRef } from "title";
import {
  Page,
  Bar,
  Icon,
  Text,
  IconDesign,
  Title,
  TitleLevel,
  Link,
  BusyIndicator,
  ValueState,
  ObjectStatus,
  BarDesign
} from "@ui5/webcomponents-react";
import { setTheme } from "@ui5/webcomponents-base/dist/config/Theme";
import MessageToast from "MessageToast";
import NoticeDialog from "NoticeDialog";
import MessageBox from "MessageBox";
// prettier-ignore
import { registerI18nLoader }
  from "@ui5/webcomponents-base/dist/asset-registries/i18n.js";
import parse from "@ui5/webcomponents-base/dist/PropertiesFileFormat.js";

import {
  setLanguage,
  getDefaultLanguage
} from "@ui5/webcomponents-base/dist/config/Language.js";
import { useI18nBundle, refI18n } from "@bsgp/form-and-table";

import { setCurrentLang, newLocaleLoaded } from "actions/user";

function isPreviewMode() {
  return window.location.pathname.startsWith("/lc-ui5/preview/");
}

function App(props) {
  useLayoutEffect(() => {
    initUI5(props);
  }, []);

  const i18nBundle = useI18nBundle("bsg");
  refI18n.current = i18nBundle;

  const { lang, systemID, supportLanguages, theme, isProduction } = props;
  const { dispatch } = props;

  const stage = isPreviewMode() ? "dev" : process.env.REACT_APP_API_STAGE_NAME;

  useEffect(() => {
    let targetLanguage = lang;
    console.log("lang", lang);
    if (!lang) {
      const defaultLanguage = navigator.language || getDefaultLanguage();
      targetLanguage = defaultLanguage;
      console.log("navigator.language", navigator.language);
    }
    if (targetLanguage && targetLanguage.length > 2) {
      targetLanguage = targetLanguage.slice(0, 2);
    }
    console.log("targetLanguage", targetLanguage);
    document.documentElement.lang = targetLanguage;
    setLanguage(targetLanguage);
    if (lang !== targetLanguage) {
      dispatch(setCurrentLang(targetLanguage));
    }
    window.sap.ui.require(["sap/base/i18n/Localization"], Localization => {
      // Change the language using the API
      Localization.setLanguage(targetLanguage);
    });
  }, [lang]);

  useLayoutEffect(() => {
    console.log("supportLanguages:", supportLanguages);
    supportLanguages.forEach(eachLang => {
      registerI18nLoader("bsg", eachLang, async () => {
        let globalProps;
        let pProps;
        const promise1 = fetch(
          [
            "https://download.bsg.support",
            "i18n/assets",
            stage,
            "global",
            `messagebundle_${eachLang}.properties`
          ].join("/")
        ).then(async res => {
          if (
            res.ok &&
            res.headers.get("content-type") === "text/x-java-properties"
          ) {
            const props = await res.text();
            globalProps = props;
          }
          return res;
        });
        const promise2 = fetch(
          [
            "https://download.bsg.support",
            "i18n/assets",
            stage,
            systemID,
            `messagebundle_${eachLang}.properties`
          ].join("/")
        ).then(async res => {
          if (
            res.ok &&
            res.headers.get("content-type") === "text/x-java-properties"
          ) {
            const props = await res.text();
            pProps = props;
          }
          return res;
        });

        await Promise.allSettled([promise1, promise2]);

        setTimeout(() => {
          dispatch(newLocaleLoaded());
        }, 0);

        // append the properties by systemID to the global properties
        // override same keys in the global properties
        const finalProps = [globalProps, pProps].filter(Boolean).join("\n");
        // this call is required for parsing the properties text
        return parse(finalProps);
      });
    });
  }, [systemID]);

  useEffect(() => {
    setTheme(theme);
    window.sap.ui.require(["sap/ui/core/Core"], function(core) {
      core.applyTheme(theme);
    });
  }, [theme]);

  return (
    <BusyIndicator style={{ display: "block", height: "100%" }} active={false}>
      <Router basename={tryit(() => encodeURI(process.env.PUBLIC_URL)) || "/"}>
        <MessageToast />
        <NoticeDialog />
        <MessageBox />
        <Page
          className={"bsgSupportPage"}
          header={
            <Bar
              design={BarDesign.Header}
              className={["bsgSupportHeader", theme].join(" ")}
              endContent={<SettingsButton />}
              startContent={
                <>
                  <HomeButton />
                  <MenuButton />
                </>
              }
            >
              {isProduction && (
                <ObjectStatus
                  icon={
                    <Icon name="high-priority" design={IconDesign.Critical} />
                  }
                  state={ValueState.Warning}
                  inverted={true}
                  className="sapUiTinyMarginEnd"
                >
                  {"PRD"}
                </ObjectStatus>
              )}

              <Title ref={titleRef} level={TitleLevel.H5}></Title>
            </Bar>
          }
          disableScrolling={false}
          floatingFooter={false}
          backgroundDesign="Transparent"
          footer={
            <Bar
              design={BarDesign.Footer}
              className={"bsgSupportFooter"}
              endContent={
                <>
                  <Text className="appVersion">
                    {process.env.REACT_APP_RELEASE_VERSION || ""}
                  </Text>
                  <Languages />
                  <Link href="/privacy">Privacy</Link>
                </>
              }
              startContent={<MessageIndicator />}
            ></Bar>
          }
        >
          {/* <Route
            render={({ location }) => {
              const newQueryString = this.handleQueryString(location.search);

              return (
                newQueryString &&
                newQueryString !== location.search && (
                  <Redirect
                    push={false}
                    to={{
                      pathname: localStorage.pathname,
                      search: newQueryString
                    }}
                  />
                )
              );
            }}
          /> */}
          <div id="content"></div>
          <Switch>
            <Route exact path={"/qsds/:qsAlias"} component={QsDs} />
            <Route exact path={"/privacy"} component={Privacy} />
            <Route strict path={"/test"} component={TestPages} />
            {/* {pid || systemID ? ( */}
            <Route strict path={"/"} component={Auth} />
            {/* ) : (
              <Route strict path={"/"} component={Login} />
            )} */}
            {/* <Route exact path={"/notauthorized"} component={Page401} />
            <Route exact path={"/pagenotfound"} component={Page404} />
            {/* <Redirect to="/" /> */}
          </Switch>
        </Page>
      </Router>
    </BusyIndicator>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.user.currentUser.theme,
    lang: state.user.currentUser.lang,
    supportLanguages: state.user.currentUser.supportLanguages,
    systemID: state.user.currentUser.systemID,
    isProduction: state.user.currentUser.system?.isProduction
  };
}

export default connect(mapStateToProps)(App);
