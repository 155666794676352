import { registerIconLoader }
  from "@ui5/webcomponents-base/dist/asset-registries/Icons.js";

registerIconLoader("bsg", () => {
  return {
    collection: "bsg",
    data: {
      ai: {
        paths: [
          `M365 419 c-4 -11 -21 -29 -38 -39 l-31 -19 27 -10 c14 -6 33 -23 41
-38 l14 -28 11 28 c6 15 22 32 36 37 14 5 25 12 25 15 0 3 -11 10 -25 15 -14
5 -30 21 -35 35 -12 30 -17 31 -25 4z`,
          `M134 403 c-12 -2 -30 -13 -40 -25 -16 -17 -19 -37 -19 -138 0 -162
-2 -160 165 -160 116 0 129 2 146 21 26 29 28 142 2 147 -16 3 -18 -5 -18 -55
0 -33 -5 -64 -12 -71 -16 -16 -220 -16 -236 0 -17 17 -16 222 1 236 7 6 42 12
77 14 50 2 65 7 65 18 0 11 -13 15 -55 16 -30 0 -65 -1 -76 -3z`,
          `M146 288 c48 -152 60 -159 93 -58 21 67 21 106 0 67 -12 -21 -52 -22
-69 -2 -19 22 -32 18 -24 -7z m73 -40 c0 -7 -4 -22 -9 -33 -7 -19 -9 -18 -19
8 -14 37 -14 37 9 37 11 0 20 -6 19 -12z`,
          `M300 235 c0 -43 4 -75 10 -75 6 0 10 32 10 75 0 43 -4 75 -10 75 -6
0 -10 -32 -10 -75z`
        ]
      }
    }
  };
});
