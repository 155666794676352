var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);
var ft_exports = {};
__export(ft_exports, {
  ft: () => ft
});
module.exports = __toCommonJS(ft_exports);
var import_lib_date = require("@bsgp/lib-date");
var import_lib_core = require("@bsgp/lib-core");
var import_functions = require("./lib/functions");
const ft = {
  Input: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const valueHelp = options.onValueHelp && {
      showValueHelp: true,
      valueHelpRequest: options.onValueHelp
    };
    const valueHelpV2 = options.valueHelpV2 && {
      showValueHelp: true,
      valueHelpV2: options.valueHelpV2
    };
    const multiple = options.multiple;
    const isAmount = options.isAmount;
    const isQuantity = options.isQuantity;
    const unit = options.unit;
    return {
      type: "Input",
      name: key || options.name,
      multiple,
      isAmount,
      isQuantity,
      textOnSort: options.textOnSort,
      unit,
      properties: (0, import_functions.removeUndefinedKeys)(__spreadValues(__spreadValues(__spreadValues(__spreadProps(__spreadValues(__spreadValues({}, multiple ? { showClearIcon: true, startSuggestion: 0, showValueHelp: false } : {}), multiple ? { tokenUpdate: options.onChange } : { change: options.onChange }), {
        submit: options.onSubmit
      }), valueHelp), valueHelpV2), options.properties)),
      items: {
        properties: __spreadProps(__spreadValues({}, (0, import_lib_core.tryit)(() => options.items.properties) || {}), {
          key: "{key}",
          text: "{text}",
          additionalText: "{additionalText}"
        }),
        list: options.list || (0, import_lib_core.tryit)(() => options.items.list) || []
      }
    };
  },
  TextArea: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "TextArea",
      name: key || options.name,
      properties: __spreadValues({
        change: options.onChange
      }, options.properties)
    };
  },
  CodeEditor: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "CodeEditor",
      name: key || options.name,
      properties: __spreadValues({
        showCustomOption: options.showCustomOption,
        change: options.onChange
      }, options.properties)
    };
  },
  Text: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: options.colorful === true ? "MessageStrip" : options.expandable === true ? "ExpandableText" : options.formatted ? "FormattedText" : "Text",
      name: key || options.name,
      styleClasses: options.styleClasses,
      textOnSort: options.textOnSort,
      properties: __spreadValues(__spreadValues({}, options.expandable === true ? { overflowMode: window.sap.m.ExpandableTextOverflowMode.Popover } : {}), options.properties)
    };
  },
  Switch: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      name: key || options.name,
      type: "Switch",
      properties: __spreadValues({
        customTextOff: options.offText,
        customTextOn: options.onText,
        change: options.onChange
      }, options.properties)
    };
  },
  Tile: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      name: key || options.name,
      type: "GenericTile",
      properties: __spreadValues({
        press: options.onPress
      }, options.properties)
    };
  },
  Select: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const itemsList = options.list || (0, import_lib_core.tryit)(() => options.items.list) || [];
    return {
      type: "Select",
      name: key || options.name,
      combine: options.combine,
      properties: __spreadValues({
        change: options.onChange
      }, options.properties),
      items: {
        properties: __spreadProps(__spreadValues({}, (0, import_lib_core.tryit)(() => options.items.properties) || {}), {
          key: "{key}",
          text: options.combine === true ? "{key} - {text}" : "{text}",
          icon: "{icon}",
          enabled: "{enabled}"
        }),
        list: ((0, import_lib_core.isArray)(itemsList) ? itemsList : []).map((each) => {
          if ((0, import_lib_core.isObject)(each)) {
            return __spreadProps(__spreadValues({}, each), { enabled: (0, import_lib_core.defined)(each.enabled, true) });
          }
          return {
            key: each,
            text: each,
            enabled: true
          };
        })
      }
    };
  },
  Number: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newProperties = __spreadValues({
      unit: options.unit
    }, options);
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach((prop) => {
        newProperties[prop] = options.properties[prop];
      });
    }
    return {
      type: "ObjectNumber",
      name: key || options.name,
      properties: newProperties
    };
  },
  AmountAsA1: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newOptions = (0, import_lib_core.defined)(options, {});
    newOptions.asA1 = true;
    return ft.Amount(key, newOptions);
  },
  Amount: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newOptions = (0, import_lib_core.defined)(options, {});
    newOptions.isAmount = true;
    delete newOptions.isQuantity;
    return ft.Number(key, newOptions);
  },
  Quantity: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newOptions = (0, import_lib_core.defined)(options, {});
    newOptions.isQuantity = true;
    delete newOptions.isAmount;
    return ft.Number(key, newOptions);
  },
  Attribute: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "ObjectAttribute",
      name: key || options.name,
      title: options.title
    };
  },
  Identifier: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "ObjectIdentifier",
      name: key || options.name,
      text: options.text,
      normalWeight: options.normalWeight
    };
  },
  Status: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newProperties = __spreadValues({
      emptyIndicatorMode: window.sap.m.EmptyIndicatorMode.On
    }, options);
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach((prop) => {
        newProperties[prop] = options.properties[prop];
      });
    }
    return {
      type: "ObjectStatus",
      name: key || options.name,
      properties: newProperties,
      textOnSort: options.textOnSort
    };
  },
  Spacer: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const newProperties = __spreadValues({}, options);
    if (options.properties) {
      delete newProperties.properties;
      Object.keys(options.properties).forEach((prop) => {
        newProperties[prop] = options.properties[prop];
      });
    }
    return {
      type: "ToolbarSpacer",
      name: key || options.name,
      properties: newProperties
    };
  },
  HTML: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "HTML",
      name: key || options.name,
      properties: __spreadValues({}, options.properties)
    };
  },
  Markdown: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Markdown",
      name: key || options.name,
      properties: __spreadValues({}, options.properties)
    };
  },
  PDFViewer: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "PDFViewer",
      name: key || options.name,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        showDownloadButton: false,
        displayType: window.sap.m.PDFViewerDisplayType.Embedded
      })
    };
  },
  DateTime: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "DateTime",
      name: key || options.name,
      format: options.format || ft.Constants.DateTime.Format.UI5,
      displayFormat: options.displayFormat,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        change: options.onChange
      })
    };
  },
  Date: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Date",
      name: key || options.name,
      format: options.format || ft.Constants.Date.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Date.Format.UI5,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        change: options.onChange
      })
    };
  },
  Time: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Time",
      name: key || options.name,
      format: options.format || ft.Constants.Time.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Time.Format.UI5,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        change: options.onChange
      })
    };
  },
  DateRange: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "DateRange",
      name: key || options.name,
      format: options.format || ft.Constants.Date.Format.UI5,
      displayFormat: options.displayFormat || ft.Constants.Date.Format.UI5,
      properties: __spreadValues({
        change: options.onChange
      }, options.properties)
    };
  },
  ComboBox: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const multiple = options.multiple;
    const config = {
      type: "ComboBox",
      name: key || options.name,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        change: options.onChange,
        showSecondaryValues: true
      }),
      multiple,
      combine: options.combine,
      sorter: options.sorter,
      items: {
        properties: __spreadProps(__spreadValues({}, (0, import_lib_core.tryit)(() => options.items.properties) || {}), {
          key: "{key}",
          text: options.combine === true ? "{key} - {text}" : "{text}",
          additionalText: "{additionalText}"
        }),
        list: (options.list || (0, import_lib_core.tryit)(() => options.items.list) || []).map(
          (each) => {
            if ((0, import_lib_core.isObject)(each)) {
              return each;
            }
            return {
              key: each,
              text: each
            };
          }
        )
      }
    };
    if (multiple) {
      delete config.properties.change;
      config.properties.selectionFinish = options.onChange;
    }
    return config;
  },
  Button: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Button",
      name: key || options.name,
      number: options.number,
      isScannerButton: options.isScannerButton,
      onScan: options.onScan,
      onError: options.onError,
      confirmMessage: options.confirmMessage,
      properties: __spreadProps(__spreadValues({}, options.properties), {
        press: options.onPress
      })
    };
  },
  ScannerButton: (...args) => {
    const result = ft.Button(...args);
    result.isScannerButton = true;
    return result;
  },
  Buttons: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "SegmentedButton",
      name: key || options.name,
      label: options.label,
      properties: __spreadValues({
        selectionChange: options.onChange || options.onSelect
      }, options.properties),
      list: options.list
    };
  },
  Comments: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "NotificationListItem",
      name: key || options.name,
      label: options.label,
      properties: __spreadValues({}, options.properties),
      list: options.list
    };
  },
  Radio: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Radio",
      name: key || options.name,
      properties: __spreadValues({
        select: options.onChange || options.onSelect
      }, options.properties),
      list: options.list
    };
  },
  Link: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Link",
      name: key || options.name,
      properties: __spreadValues({
        text: options.text,
        press: options.onPress
      }, options.properties)
    };
  },
  List: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "List",
      name: key || options.name,
      properties: __spreadValues({}, options.properties),
      items: {
        properties: __spreadProps(__spreadValues({}, (0, import_lib_core.tryit)(() => options.items.properties) || {}), {
          press: options.items.onPress
        }),
        list: options.list || (0, import_lib_core.tryit)(() => options.items.list) || []
      }
    };
  },
  CheckBox: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "CheckBox",
      name: key || options.name,
      hiddenData: options.hiddenData,
      styleClasses: options.styleClasses,
      properties: __spreadValues({
        select: options.onChange || options.onSelect,
        text: options.text
      }, options.properties)
    };
  },
  Uploader: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "FileUploader",
      name: key || options.name,
      properties: __spreadValues({
        change: options.onChange
      }, options.properties)
    };
  },
  Image: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Image",
      name: key || options.name,
      bindArray: options.bindArray,
      hiddenData: options.hiddenData,
      properties: __spreadValues({}, options.properties)
    };
  },
  Avatar: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Avatar",
      name: key || options.name,
      properties: __spreadValues({}, options.properties)
    };
  },
  StepInput: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "StepInput",
      name: key || options.name,
      label: options.label,
      hiddenData: options.hiddenData,
      properties: __spreadValues({
        change: options.onChange
      }, options.properties)
    };
  },
  Label: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Label",
      name: key || options.name,
      properties: __spreadValues({
        labelFor: options.for
      }, options.properties)
    };
  },
  RichTextEditor: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "RichTextEditor",
      name: key || options.name,
      properties: __spreadValues({
        onChange: options.onChange
      }, options.properties)
    };
  },
  Section: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    const result = __spreadProps(__spreadValues({}, options), {
      type: "Section",
      sectionType: options.type,
      name: key || options.name,
      properties: __spreadValues({}, options.properties)
    });
    if (options.onToggle) {
      result.properties.onToggle = options.onToggle;
    }
    if (options.onStepChange) {
      result.properties.onStepChange = options.onStepChange;
    }
    return result;
  },
  Title: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return {
      type: "Title",
      name: key || options.name,
      properties: __spreadValues({
        text: options.text
      }, options.properties)
    };
  },
  NumericSideIndicator: (...args) => {
    const [key, options] = (0, import_functions.extractArgs)(args);
    return __spreadProps(__spreadValues({}, options), {
      type: "NumericSideIndicator",
      name: key || options.name,
      properties: __spreadValues({
        titleText: options.title,
        unit: options.unit
      }, options.properties)
    });
  }
};
ft.Constants = {
  DateTime: {
    Format: {
      Moment: import_lib_date.format.default.datetime,
      UI5: import_lib_date.format.ui5.datetime
    }
  },
  Date: {
    Format: {
      Moment: import_lib_date.format.default.date,
      UI5: import_lib_date.format.ui5.date
    }
  },
  Time: {
    Format: {
      Moment: import_lib_date.format.default.time,
      UI5: import_lib_date.format.ui5.time
    }
  }
};
